<template>
  <div>
    <!--Section Banner-->
    <section class="hero hero1 d-flex flex-column justify-center">
      <v-container fluid class="px-sm-8 px-6 text-center">
        <v-row justify="space-around" dense no-gutters>
          <v-col cols="12">
            <div class="hero-title">To your wellbeing.</div>
          </v-col>
        </v-row>
        <v-row justify="space-around" dense no-gutters>
          <v-col sm="12" cols="9">
            <div class="section-subtitle">
              Evidence-based medicine and wellness to help
              <br class="d-none d-sm-flex" />
              you feel good and do more.
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-16">
          <v-col>
            <v-btn text href="#book-consultation" class="btn-custom mb-4">
              Book A Consultation
            </v-btn>
            <div id="text-book-consultation">
              Consult our team of medical professionals
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!--Section Our Practice-->
    <section id="our-practice">
      <v-container fluid class="px-sm-16 px-10">
        <!--Show Only On MD and down-->
        <v-row dense class="px-md-12 px-sm-6 hidden-lg-and-up">
          <v-col cols="12" class="text-center">
            <div class="section-tag">Our Practice</div>
            <div class="section-title">Interprofessional Collaboration</div>
          </v-col>
        </v-row>
        <!--End MD and down-->

        <v-row dense align="center" class="px-md-12 px-sm-6">
          <v-col
            lg="7"
            cols="12"
            class="pr-lg-14 text-lg-left text-center"
            :order="$vuetify.breakpoint.mdAndDown ? 2 : 1"
          >
            <!--Show Only On LG and up-->
            <div class="hidden-md-and-down">
              <div class="section-tag">Our Practice</div>
              <div class="section-title">Interprofessional Collaboration</div>
            </div>
            <!--End LG and up-->

            <p class="section-subtitle mt-lg-0 mb-lg-15 mt-6 mb-10">
              We utilize our team of highly qualified and certified pharmacists
              and medical professionals to ensure that you are well taken cared
              of.
            </p>
            <div>
              <v-dialog v-model="dialog" max-width="850px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text class="btn-custom" v-bind="attrs" v-on="on">
                    LEARN MORE
                  </v-btn>
                </template>
                <v-card class="modal px-sm-16 px-10 py-16" rounded="0">
                  <v-btn
                    text
                    v-ripple="false"
                    @click="dialog = false"
                    class="close"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <div class="section-tag text-center mt-6">
                    Inter-Professional Collaboration (IPC)
                  </div>
                  <div class="section-title text-center mb-12">
                    Teamwork Makes The Dreamwork
                  </div>
                  <div class="section-text">
                    <p>
                      The World Health Organization defines interprofessional
                      collaboration (IPC) in healthcare as “multiple health
                      workers from different professional backgrounds work[ing]
                      together with patients, families, carers (caregivers), and
                      communities to deliver the highest quality of care.”
                    </p>
                    <p>
                      Numerous studies across the world have proven that IPC
                      leads to an improvement in health care, which calls for
                      increased IPC nationally and internally through
                      interprofessional education in health professions.
                    </p>
                    <p class="my-10"><b>References</b></p>
                    <p>
                      <u>
                        Bart N. Green, Claire D. Johnson; Interprofessional
                        collaboration in research, education, and clinical
                        practice: working together for a better future. J
                        Chiropr Educ 1 March 2015; 29 (1): 1–10.
                        <br /><br />
                        Duru, O. K., Schmittdiel, J. A., Dyer, W. T., Parker, M.
                        M., Uratsu, C. S., Chan, J., & Karter, A. J. (2010).
                        Mail-order pharmacy use and adherence to
                        diabetes-related medications. The American journal of
                        managed care, 16(1), 33–40.
                        <br /><br />
                        Lee, J. K., Grace, K. A., & Taylor, A. J. (2006). Effect
                        of a pharmacy care program on medication adherence and
                        persistence, blood pressure, and low-density lipoprotein
                        cholesterol: a randomized controlled trial. JAMA,
                        296(21), 2563–2571.
                        <br /><br />
                        Viswanathan, M., Golin, C. E., Jones, C. D., Ashok, M.,
                        Blalock, S. J., Wines, R. C., Coker-Schwimmer, E. J.,
                        Rosen, D. L., Sista, P., & Lohr, K. N. (2012).
                        Interventions to improve adherence to self-administered
                        medications for chronic diseases in the United States: a
                        systematic review. Annals of internal medicine, 157(11),
                        785–795.
                      </u>
                    </p>
                  </div>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
          <v-col
            lg="5"
            cols="12"
            :order="$vuetify.breakpoint.mdAndDown ? 1 : 2"
            class="red"
          >
            <v-img
              src="../assets/img/try2.jpg"
              alt="OurPractice1"
              max-height="550"
              max-width="470"
              class="pic mx-auto"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!--Section Our Team-->
    <section id="our-team">
      <v-container fluid class="px-sm-16 px-10 text-center">
        <v-row dense class="px-md-12 px-sm-6">
          <v-col>
            <div class="section-tag">Our Team</div>
            <div class="section-title">A new kind of care.</div>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="slider text-center">
        <VueSlickCarousel v-bind="settingsTeam">
          <v-container v-for="(people, index) in team" :key="index">
            <button @click.stop="people.dialog = true" class="slider-item">
              <v-avatar :size="$vuetify.breakpoint.xs ? 170 : 218">
                <img
                  :src="require(`@/assets/img/${people.src}`)"
                  :alt="people.alt"
                />
              </v-avatar>
              <div class="slider-text mt-4">
                <div class="people-name">{{ people.name }}</div>
                <div class="section-text">{{ people.job }}</div>
              </div>
            </button>
            <v-dialog v-model="people.dialog" max-width="850px">
              <v-card class="modal px-sm-16 px-10 py-16" rounded="0">
                <v-btn
                  text
                  v-ripple="false"
                  @click="people.dialog = false"
                  class="close"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <div class="text-center mt-6 mb-14">
                  <v-avatar :size="$vuetify.breakpoint.xs ? 170 : 218">
                    <img
                      :src="require(`@/assets/img/${people.src}`)"
                      :alt="people.alt"
                    />
                  </v-avatar>
                  <div class="people-name mt-2">{{ people.name }}</div>
                  <div class="section-text">
                    {{ people.dialog_graduate }}
                  </div>
                </div>
                <div class="section-text" v-html="people.dialog_text"></div>
              </v-card>
            </v-dialog>
          </v-container>
        </VueSlickCarousel>
      </v-container>
    </section>
  </div>
</template>


<style>
/*Remark Change */
#our-team .slick-track {
  margin: auto;
  padding: 10px 0;
}
</style>

<style scoped>
.hero #text-book-consultation {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #404040;
}

.modal {
  position: relative;
}

.modal > .section-text >>> a {
  color: #a56c6a;
  text-decoration: none;
}

.modal > .section-text >>> a:hover {
  text-decoration: underline;
}

.close {
  position: absolute;
  top: 20px;
  right: 0px;
  background-color: #ffffff;
  color: #2b2b2b;
  z-index: 300;
}

.people-name {
  font-family: "BigCaslon-Rom";
  font-size: 35px;
}

/* Remark Change */
#our-team .slider {
  width: 80%;
}

/*add */
@media (max-width: 800px) {
  .people-name {
    font-size: 27px;
  }
  /*add */
  #our-team .slider {
    width: 100%;
    padding: 0px;
  }
}

#our-team .slider-item:hover > .v-avatar {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
/*End Remark */

#our-team .slider-item:hover > .slider-text > * {
  color: #a56c6a;
}

#our-team .slider-item > .slider-text {
  position: relative;
  top: 0px;
}

#our-team .slider-item:hover > .slider-text {
  top: 20px;
}
</style>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: { VueSlickCarousel },
  data() {
    return {
      dialog: false,
      settingsTeam: {
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      team: [
        {
          dialog: false,
          src: "DrKevinOng.png",
          alt: "Dr Kevin Ong",
          name: "Dr Kevin Ong",
          job: "Pharmacist",
          dialog_graduate: "(Doctor of Pharmacy, BSc. In Biochemistry, USA)",
          dialog_text:
            "<p>Dr. Kevin Ong is a Doctor of Pharmacy (PharmD) graduate from the Massachusetts College of Pharmacy and Health Sciences (MCPHS University) at Worcester, MA, USA. He was also awarded a graduate certificate in Medication Safety upon his graduation from pharmacy school. </p>" +
            "<p>Dr. Ong was previously featured on <a href='https://mobile.swiperxapp.com/pharmacy-leaders-malaysia-kevin-ong/' target='_blank'>“Pharmacy Leaders of Malaysia”</a> in recognition for his volunteer work with the United Nations High Commissioner for Refugees (UNHCR) as a Diabetes Educator for healthcare professionals. </p>" +
            "<p>With years of both clinical and retail experience, Dr. Ong serves as Medwell’s Chief-Pharmacist. </p>",
        },
        {
          dialog: false,
          src: "DrSharonLee.png",
          alt: "Dr Sharon Lee",
          name: "Dr Sharon Lee",
          job: "Pharmacist",
          dialog_graduate: "(Doctor of Pharmacy, USA)",
          dialog_text:
            "<p>Dr. Sharon Lee is a Doctor of Pharmacy graduate from the Massachusetts College of Pharmacy and Health Sciences (MCPHS University) at Boston, MA, USA and is a licensed pharmacist in the United States. </p>" +
            "<p>During her time in the USA, she had hands-on experience with various aspects of pharmacy practice, including pharmaceutical compounding, women’s health, and dermatology. She strives to incorporate her experiences from the USA into her clinical practices at Medwell. </p>" +
            "Dr. Lee is also currently pursuing her Masters of Science in Clinical Research from MCPHS University. </p>",
        },
        {
          dialog: false,
          src: "profile_user.jpg",
          alt: "Profile User",
          name: "Dr Eric Ong",
          job: "Pharmacist",
          dialog_graduate: "(Doctor of Pharmacy, BSc. In Biochemistry, USA)",
          dialog_text:
            "<p>Dr. Kevin Ong is a Doctor of Pharmacy (PharmD) graduate from the Massachusetts College of Pharmacy and Health Sciences (MCPHS University) at Worcester, MA, USA. He was also awarded a graduate certificate in Medication Safety upon his graduation from pharmacy school. </p>" +
            "<p>Dr. Ong was previously featured on <a href='https://mobile.swiperxapp.com/pharmacy-leaders-malaysia-kevin-ong/' target='_blank'>“Pharmacy Leaders of Malaysia”</a> in recognition for his volunteer work with the United Nations High Commissioner for Refugees (UNHCR) as a Diabetes Educator for healthcare professionals. </p>" +
            "<p>With years of both clinical and retail experience, Dr. Ong serves as Medwell’s Chief-Pharmacist. </p>",
        },
        // {
        //   dialog: false,
        //   src: "DrSharonLee.png",
        //   alt: "Dr Sharon Lee",
        //   name: "Dr Alice Lee",
        //   job: "Pharmacist",
        //   dialog_graduate: "(Doctor of Pharmacy, USA)",
        //   dialog_text:
        //     "<p>Dr. Sharon Lee is a Doctor of Pharmacy graduate from the Massachusetts College of Pharmacy and Health Sciences (MCPHS University) at Boston, MA, USA and is a licensed pharmacist in the United States. </p>" +
        //     "<p>During her time in the USA, she had hands-on experience with various aspects of pharmacy practice, including pharmaceutical compounding, women’s health, and dermatology. She strives to incorporate her experiences from the USA into her clinical practices at Medwell. </p>" +
        //     "Dr. Lee is also currently pursuing her Masters of Science in Clinical Research from MCPHS University. </p>",
        // },
      ],
    };
  },
};
</script>