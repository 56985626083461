<template>
  <nav>
    <!--Navbar-->
    <v-app-bar
      app
      flat
      class="pl-sm-8 pl-2 pr-2 white"
      height="70px"
      @scroll="handleScroll"
    >
      <a href="/" class="d-flex align-center">
        <v-img
          class="mr-5"
          src="../assets/img/LogoMedwell.png"
          max-height="23"
          max-width="40"
          contain
        >
        </v-img>
        <v-toolbar-title
          class="text-decoration-none primaryText--text sitename"
        >
          {{ siteName }}
        </v-toolbar-title>
      </a>

      <v-spacer></v-spacer>

      <v-app-bar-nav-icon
        class="hidden-md-and-up primaryText--text mr-sm-8"
        v-ripple="false"
        @click="drawer = !drawer"
      >
      </v-app-bar-nav-icon>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          text
          v-ripple="false"
          v-for="link in links"
          :key="link.text"
          :href="link.route"
          class="menu primaryText--text text-capitalize mx-8"
        >
          {{ link.text }}
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <!--Right Drawer-->
    <v-navigation-drawer
      app
      v-model="drawer"
      temporary
      right
      disable-resize-watcher
      class="hidden-md-and-up"
      :hide-overlay="$vuetify.breakpoint.mdAndUp"
    >
      <v-list-item class="drawer-brand">
        <v-img
          class="mr-5"
          src="../assets/img/LogoMedwell.png"
          max-height="23"
          max-width="40"
          contain
        >
        </v-img>
        <v-list-item-title class="sitename">{{ siteName }}</v-list-item-title>
      </v-list-item>

      <v-list-item-group v-model="group">
        <v-list-item
          v-for="link in links"
          :key="link.text"
          :href="link.route"
          class="drawer-item up"
        >
          <v-list-item-title class="drawer-text">
            {{ link.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-navigation-drawer>
  </nav>
</template>

<style scoped>
.sitename {
  font-family: "Hatton-Medium";
  font-size: 26px;
  margin-top: 5px;
  margin-left: 2px;
}

.menu.v-btn:not(.v-btn--round).v-size--default {
  padding: 0;
}

.menu.v-btn.v-size--default {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-indent: 0;
  position: relative;
}

.menu.v-btn.v-size--default::after {
  content: "";
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  height: 0.2em;
  border-radius: 10px;
  background-color: #2b2b2b;
  -webkit-transform: scaleX(0) translateY(-5px);
  -ms-transform: scaleX(0) translateY(-5px);
  transform: scaleX(0) translateY(-5px);
  -webkit-transition: -webkit-transform 0.2s ease-in 0s;
  transition: -webkit-transform 0.2s ease-in 0s;
  -o-transition: transform 0.2s ease-in 0s;
  transition: transform 0.2s ease-in 0s;
  transition: transform 0.2s ease-in 0s, -webkit-transform 0.2s ease-in 0s;
}

.menu.v-btn.v-size--default:hover::after,
.menu.active.v-btn.v-size--default::after {
  -webkit-transform: scaleX(0.1) translateY(0);
  -ms-transform: scaleX(0.1) translateY(0);
  transform: scaleX(0.1) translateY(0);
}
/**dddd */
.v-navigation-drawer {
  transition-duration: 0.4s;
}

.v-list-item.drawer-brand {
  height: 70px;
}

.v-list-item__title.drawer-text {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #2b2b2b;
}

.v-list-item.drawer-item.up.active {
  background-color: #ced5cc;
}

.v-list-item.drawer-item.up:hover {
  background-color: #ced5cc;
}

.v-list-item.drawer-item.down.active {
  background-color: #f0f2f0;
}

.v-list-item.drawer-item.down:hover {
  background-color: #f0f2f0;
}

/* .v-list-item.active .drawer-text {
  color: #ffffff;
} */
</style>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      isAddClass: false,
      siteName: "Medwell",
      links: [
        { text: "Pharmacy", route: "/" },
        { text: "Our Story", route: "/story" },
        { text: "For Doctors", route: "/doctors" },
        { text: "Our Blog", route: "/blog" },
      ],
      drawer: false,
      group: null,
    };
  },

  methods: {
    handleScroll() {
      let header = document.querySelector(
        ".theme--light.v-app-bar.v-toolbar.v-sheet "
      );
      let sidebar = document.querySelector(".v-navigation-drawer");
      if (window.scrollY > 10) {
        header.style.backgroundColor = "#CED5CC";
        header.classList.remove("white");
        sidebar.style.backgroundColor = "#CED5CC";
        $(".v-navigation-drawer .drawer-item").each(function () {
          $(".v-navigation-drawer .drawer-item").addClass("down");
          $(".v-navigation-drawer .drawer-item").removeClass("up");
        });
      } else {
        header.classList.add("white");
        header.style.removeProperty("background-color");
        sidebar.style.backgroundColor = "#FFFFFF";
        $(".v-navigation-drawer .drawer-item").each(function () {
          $(".v-navigation-drawer .drawer-item").removeClass("down");
          $(".v-navigation-drawer .drawer-item").addClass("up");
        });
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    var pgurl = window.location.pathname;
    $(".v-toolbar__items .menu").each(function () {
      if ($(this).attr("href") == pgurl || $(this).attr("href") == " ") {
        $(this).addClass("active");
      }
    });

    if (pgurl.indexOf("blog" && "details") > -1) {
      $(".v-toolbar__items .menu").each(function () {
        if ($(this).attr("href") == "/blog") {
          $(this).addClass("active");
        }
      });
    }

    $(".v-item-group .v-list-item--link").each(function () {
      if ($(this).attr("href") == pgurl || $(this).attr("href") == " ") {
        $(this).addClass("active");
      }
    });

    if (pgurl.indexOf("blog" && "details") > -1) {
      $(".v-item-group .v-list-item--link").each(function () {
        if ($(this).attr("href") == "/blog") {
          $(this).addClass("active");
        }
      });
    }
  },
};
</script>
