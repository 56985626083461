<template>
  <v-app>
    <Navbar v-if="!$route.meta.hideNavigation" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<style>
</style>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "Medwell",
  components: { Navbar },
  data: () => ({
    //
  }),
};
</script>
