<template>
  <section id="blog-page" class="medwell-blog">
    <v-container fluid class="text-center px-10 px-sm-16">
      <v-row class="px-sm-6 px-md-12" justify="space-around" dense>
        <v-col cols="12">
          <div class="section-tag">The Medwell Blog</div>
        </v-col>
        <v-col cols="9">
          <div class="section-title">Hello, Dear.</div>
        </v-col>
      </v-row>
      <v-row class="px-sm-6 px-md-12">
        <v-col
          cols="12"
          md="6"
          lg="4"
          v-for="blog in blogList"
          :key="blog.id"
          class="d-flex flex-column my-5"
        >
          <div class="card-wrap d-flex flex-column flex-grow-1 mx-auto">
            <v-card
              :href="'/blog/' + blog.id + '/details'"
              :ripple="false"
              class="mx-auto text-left secondaryColor flex-grow-1"
              max-width="500"
            >
              <v-img
                class="mx-auto"
                :src="require(`@/assets/img/blog/${blog.img_name}`)"
                :alt="blog.img_alt"
                max-width="500"
                max-height="500"
                aspect-ratio="1"
              >
              </v-img>
              <div class="card-date">
                <div class="card-date-day">
                  {{ blog.date_day }}
                </div>
                <!-- <div class="card-date-month-year text-uppercase">
                  {{ blog.date_month_year }}
                  {{ monthDate(blog.date) }}
                </div> -->
              </div>
              <v-card-title class="primaryText--text">{{
                blog.title
              }}</v-card-title>
              <v-card-text
                class="primaryText--text"
                v-html="blog.content"
                tag="span"
              >
              </v-card-text>
              <span>+more</span>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-row justify="space-around" class="pt-2 pt-md-10">
        <v-pagination
          v-model="page"
          :length="pages"
          :total-visible="totalVisibles"
          @input="updatePage"
          color="primaryText"
        ></v-pagination>
      </v-row>
    </v-container>
    <!-- <div id="blog-top-background">
      <BlogTopBackground />
    </div>
    <div id="blog-bottom-background">
      <BlogBottomBackground />
    </div> -->
  </section>
</template>

<script>
// import $ from "jquery";
// import * as cutter from "@/assets/js/clamp-max-line-cutter/line-cutter.js";
// import BlogTopBackground from "@/components/animatedSVG/Page4/BlogTopBackground";
// import BlogBottomBackground from "@/components/animatedSVG/Page4/BlogBottomBackground";

export default {
  // components: {
  //   BlogTopBackground,
  //   BlogBottomBackground,
  // },
  data() {
    return {
      day: 0,
      page: 1, //current page
      pageSize: 0, //number of blogs per page
      totalVisibles: 0, //number of visible page buttons
      listCount: 0,
      blogList: [],
      blogs: [
        {
          id: 1,
          img_name: "Antimicrobial Resistance.jpg",
          img_alt: "Antimicrobial Resistance: The Role of Healthcare Providers",
          date_day: "27",
          date_month_year: "Nov 2019",
          date: "2019-11-27",
          title: "Antimicrobial Resistance: The Role of Healthcare Providers",
          content:
            "The World Health Organization (WHO) considers antibiotic resistance to be one of the major public health threats of this century.<sup>1</sup>",
        },
        {
          id: 2,
          img_name: "Childhood Obesity.jpg",
          img_alt: "Childhood Obesity",
          date_day: "1",
          date_month_year: "Sept 2021",
          date: "2021-09-01",
          title: "Childhood Obesity a Growing Concern in Malaysia",
          content:
            "Obesity has been a growing modern age concern on a global scale for the past few decades. With the modernization of societies, daily living conveniences have not only led to poor diet, but also contributed to sedentary lifestyles.",
        },
        {
          id: 3,
          img_name: "Pharmaceutical Compounding.jpg",
          img_alt: "Pharmaceutical Compounding",
          date_day: "3",
          date_month_year: "Sept 2021",
          date: "2021-09-03",
          title: "Pharmaceutical Compounding",
          content:
            "Pharmaceutical compounding is the creation or the making of a pharmaceutical preparation which more often than not incorporates one or more drug contents into the preparation.",
        },
        {
          id: 4,
          img_name: "Shift from Management to Prevention of Diabetes.jpg",
          img_alt: "Shift from Management to Prevention of Diabetes",
          date_day: "7",
          date_month_year: "Sept 2021",
          date: "2021-09-07",
          title: "Shift from Management to Prevention of Diabetes",
          content:
            "Malaysians highly pride themselves in their local cuisine, with flavors deriving from a blend of cultures.",
        },
        {
          id: 5,
          img_name: "The State of Disconnected Care in Malaysia.jpg",
          img_alt: "The State of Disconnected Care in Malaysia",
          date_day: "9",
          date_month_year: "Sept 2021",
          date: "2021-09-09",
          title: "The State of Disconnected Care in Malaysia",
          content:
            "Collaboration between healthcare workers has been shown to improve patient outcomes in many ways including the reduction in preventable adverse drug reactions<sup>1,2</sup>, decreasing morbidity and mortality rates<sup>3</sup>, and optimizing medication dosages<sup>4</sup>.",
        },
        {
          id: 6,
          img_name: "Dispensing Separation.jpg",
          img_alt: "Dispensing Separation",
          date_day: "12",
          date_month_year: "Sept 2021",
          date: "2021-09-12",
          title:
            "Dispensing Separation: Are pharmacists actually ready for it?",
          content:
            "Dispensing separation, also known as separation of prescribing and dispensing, is a medical and pharmacy practice where the prescribing physician provides the patient with a written or electronic prescription for the dispensing of the prescribed medication at a pharmacy.",
        },
        {
          id: 7,
          img_name: "The Utility of E-Prescribing System in Malaysia.jpg",
          img_alt: "The Utility of E-Prescribing System in Malaysia",
          date_day: "17",
          date_month_year: "Sept 2021",
          date: "2021-09-17",
          title: "The Utility of E-Prescribing System in Malaysia",
          content:
            "Electronic prescribing (e-prescribing) is the computer-based electronic generation, transmission, and filling of a medical prescription, which replaces or is supplementary to handwritten or faxed prescriptions.",
        },
        {
          id: 8,
          img_name: "Erectile Dysfunction in Malaysian Men.jpg",
          img_alt: "Erectile Dysfunction in Malaysian Men",
          date_day: "18",
          date_month_year: "Sept 2021",
          date: "2021-09-18",
          title: "Erectile Dysfunction in Malaysian Men",
          content:
            "Erectile dysfunction (ED) is a male sexual disorder in which one experiences difficulty in achieving or maintaining an erection.<sup>1</sup>.",
        },
        {
          id: 9,
          img_name: "Pharmacists and the Elderly in Malaysia.jpg",
          img_alt: "Pharmacists and the Elderly in Malaysia",
          date_day: "21",
          date_month_year: "Sept 2021",
          date: "2021-09-21",
          title: "Pharmacists and the Elderly in Malaysia",
          content:
            "The human body changes over time, which means that our health care needs changes as we age.",
        },
        {
          id: 10,
          img_name: "Healthcare Collaboration, Not War Op-Ed.jpg",
          img_alt: "Healthcare Collaboration, Not War Op-Ed",
          date_day: "23",
          date_month_year: "Sept 2021",
          date: "2021-09-23",
          title: "Healthcare Collaboration, Not War Op-Ed",
          content:
            "In Malaysia, many patients with one or more chronic diseases tend to visit different physicians or specialists, and for subsequent refills of their medications, many choose to pick them up at local community pharmacies for convenience, and it is an undeniable fact that a number of patients are price conscious as well when it comes to replenishing their chronic medications.",
        },
        {
          id: 11,
          img_name:
            "Utilization of Health Information Technology in Malaysia.jpg",
          img_alt: "Utilization of Health Information Technology in Malaysia",
          date_day: "24",
          date_month_year: "Sept 2021",
          date: "2021-09-24",
          title: "Utilization of Health Information Technology in Malaysia",
          content:
            "An optimal patient care plan stems from good communication between healthcare professionals and most importantly – accurate health or medical record keeping.",
        },
        {
          id: 12,
          img_name: "Pharmacist’s Role in Immunization.jpg",
          img_alt: "Pharmacist’s Role in Immunization",
          date_day: "25",
          date_month_year: "Sept 2021",
          date: "2021-09-25",
          title: "Pharmacist’s Role in Immunization",
          content:
            "In light of the recent news of a polio case in Malaysia after 27 years of being polio-free, discussions about immunizations or vaccinations are ignited once again even though the polio case in Tuaran, Sabah was suspected to have been brought in from outside of Malaysia.",
        },
        {
          id: 13,
          img_name: "Medication Safety.jpg",
          img_alt: "Medication Safety",
          date_day: "2",
          date_month_year: "Jan 2020",
          date: "2020-01-02",
          title: "Medication Safety: Are We Practicing It?",
          content:
            "Medications are used to treat, manage, and sometimes to prevent diseases.",
        },
        {
          id: 14,
          img_name: "Mental Health Illness is Not a Weakness.jpg",
          img_alt: "Mental Health Illness is Not a Weakness",
          date_day: "26",
          date_month_year: "Sept 2021",
          date: "2021-09-26",
          title: "Mental Health Illness is Not a Weakness",
          content:
            "Over the past decade, mental health disorders have become one of the most prevalent public health concerns all over the world.",
        },
        {
          id: 15,
          img_name: "Patient Counselling.jpg",
          img_alt: "Patient Counselling",
          date_day: "26",
          date_month_year: "Sept 2021",
          date: "2021-09-26",
          title: "Patient Counselling: A Refresher for Pharmacists in Malaysia",
          content:
            "Pharmacist as a profession was traditionally viewed as nothing more than a “pill counter”.",
        },
        {
          id: 16,
          img_name:
            "What a Pharmacist Should Know About Pediatric Pharmacy.jpg",
          img_alt: "What a Pharmacist Should Know About Pediatric Pharmacy",
          date_day: "27",
          date_month_year: "Sept 2021",
          date: "2021-09-27",
          title: "What a Pharmacist Should Know About Pediatric Pharmacy",
          content:
            "The pediatric population is a vulnerable group in terms of medication safety, and the saying that most pediatric pharmacists go by is “kids are not just little adults.”",
        },
        {
          id: 17,
          img_name: "Polypharmacy.jpg",
          img_alt: "Polypharmacy",
          date_day: "27",
          date_month_year: "Sept 2021",
          date: "2021-09-27",
          title:
            "Polypharmacy: Pharmacist’s Importance in Medication Management",
          content:
            "Medications are used as pharmacotherapy to treat or prevent diseases.",
        },
        {
          id: 18,
          img_name: "Medication Safety in Pregnancy and Lactation.jpg",
          img_alt: "Medication Safety in Pregnancy and Lactation",
          date_day: "28",
          date_month_year: "Sept 2021",
          date: "2021-09-28",
          title: "Medication Safety in Pregnancy and Lactation",
          content:
            "As a pharmacist, being asked to give advice about medication use during pregnancy or lactation can be quite intimidating.",
        },
        {
          id: 19,
          img_name: "The Role of a Pharmacist in Public Health.jpg",
          img_alt: "The Role of a Pharmacist in Public Health",
          date_day: "28",
          date_month_year: "Sept 2021",
          date: "2021-09-28",
          title: "The Role of a Pharmacist in Public Health",
          content:
            "Pharmacists are not formally recognized as a profession that plays a role in the public health work force, unlike public health physicians, nurses, and even nutritionists. ",
        },
        {
          id: 20,
          img_name: "Medication Management during Ramadan.jpg",
          img_alt: "Medication Management during Ramadan",
          date_day: "29",
          date_month_year: "Sept 2021",
          date: "2021-09-29",
          title: "Medication Management during Ramadan",
          content:
            "Ramadan is the most holy month of the year in the Islamic culture. Practicing Muslims observe the month of Ramadan through fasting from dawn to sunset, abstinence from pleasures and prayers.",
        },
        {
          id: 21,
          img_name: "It’s time to talk about sexual health.jpg",
          img_alt: "It’s time to talk about sexual health",
          date_day: "29",
          date_month_year: "Sept 2021",
          date: "2021-09-29",
          title: "It’s time to talk about sexual health",
          content:
            "Sexual health is a sense of sexual well-being on a personal level, as well as the absence of sexual behavior related diseases, infections or illnesses.<sup>1</sup>",
        },
        {
          id: 22,
          img_name: "Smoking Cessation a Challenge in Malaysia.jpg",
          img_alt: "Smoking Cessation a Challenge in Malaysia",
          date_day: "30",
          date_month_year: "Sept 2021",
          date: "2021-09-30",
          title: "Smoking Cessation a Challenge in Malaysia",
          content:
            "Cigarette smoking is a chronic, relapsing substance use disorder that is sustained by the addiction to nicotine.",
        },
        {
          id: 23,
          img_name: "Telepharmacy.jpg",
          img_alt: "Telepharmacy",
          date_day: "1",
          date_month_year: "Oct 2021",
          date: "2021-10-01",
          title: "Telepharmacy: Is It Practical for Malaysians?",
          content:
            "The medical field is constantly improving with advances in technology and medical innovations.",
        },
        {
          id: 24,
          img_name:
            "E-cigarettes and Vapes are Not Aids for Smoking Cessation.jpg",
          img_alt: "E-cigarettes and Vapes are Not Aids for Smoking Cessation",
          date_day: "3",
          date_month_year: "Oct 2021",
          date: "2021-10-03",
          title: "E-cigarettes and Vapes are Not Aids for Smoking Cessation",
          content:
            "E-cigarettes and vaping devices are battery-operated devices that heat up a liquid to form vapors or aerosol that the user then inhales.",
        },
        {
          id: 25,
          img_name: "Women’s Health.jpg",
          img_alt: "Women’s Health",
          date_day: "4",
          date_month_year: "Oct 2021",
          date: "2021-10-04",
          title: "Women’s Health",
          content:
            "Women’s health is an area of medicine that has gained attention over the last few years.",
        },
      ],
    };
  },
  created() {
    let _this = this;
    _this.setPageSizesAndTotalVisibles();
    _this.initPage();
    _this.updatePage(_this.page);
  },
  methods: {
    setPageSizesAndTotalVisibles: function () {
      let _this = this;
      if (window.innerWidth < 600) {
        _this.pageSize = 5;
        _this.totalVisibles = 3;
      } else if (window.innerWidth < 960) {
        _this.pageSize = 8;
        _this.totalVisibles = 5;
      } else {
        _this.pageSize = 9;
        _this.totalVisibles = 7;
      }
    },
    initPage: function () {
      let _this = this;
      _this.sortByDate();
      _this.listCount = _this.blogs.length;
      if (_this.listCount < _this.pageSize) {
        _this.blogList = _this.blogs;
      } else {
        _this.blogList = _this.blogs.slice(0, _this.pageSize);
      }
    },
    updatePage: function (pageIndex) {
      let _this = this;
      let _start = (pageIndex - 1) * _this.pageSize;
      let _end = pageIndex * _this.pageSize;
      _this.blogList = _this.blogs.slice(_start, _end);
      window.scrollTo(0, 0);
      // alert(pageIndex);
      // alert(_start);
      // alert(_end);
    },
    sortByDate: function () {
      //sort the blogs by latest date
      return this.blogs.sort((a, b) => new Date(b.date) - new Date(a.date));
    },
  },
  computed: {
    pages() {
      let _this = this;
      if (_this.pageSize == null || _this.listCount == null) return 0;
      return Math.ceil(_this.listCount / _this.pageSize);
    },
    monthDate(prop) {
      var m = new Date(prop);
      // var m = new Date("2021-09-27");
      console.log(m);
      return m.getDate();

      // return 0;
    },
  },
  // mounted() {
  //   var flagscroll = true;
  //   // Line animation on scroll
  //   $(window).on("scroll", function () {
  //     var top = $(window).scrollTop() + $(window).height(),
  //       isVisible = top > $("#blog-bottom-background").offset().top;
  //     if (flagscroll == true && isVisible) {
  //       flagscroll = false;
  //       $("#blog-bottom-background").toggleClass(
  //         "blog-bottom-animation",
  //         isVisible
  //       );
  //     }
  //   });
  // },
};
</script>

<style scoped>
#blog-page {
  /* -webkit-transform: translateY(120px);
  -ms-transform: translateY(120px);
  transform: translateY(120px); */
  -webkit-transform: translateY(40px);
  -ms-transform: translateY(40px);
  transform: translateY(40px);
}

/* #blog-top-background {
  position: absolute;
  z-index: -100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#blog-bottom-background {
  position: absolute;
  z-index: -100;
  /* background-color: aqua; */
/* width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
} */
</style>

<style>
/* Customize hover color of pagination */
.medwell-blog
  .theme--light.v-pagination
  .v-pagination__item:not(.v-pagination__item--active):hover {
  background: #ced5cc;
  color: #ffffff;
}

.medwell-blog .theme--light.v-pagination .v-pagination__navigation:hover {
  background: #ced5cc !important;
  color: #ffffff !important;
}

.medwell-blog
  .theme--light.v-pagination
  .v-pagination__navigation
  .theme--light.v-icon:hover {
  color: #ffffff;
}

/* .medwell-blog .theme--light.v-pagination .v-pagination__navigation:hover {
  background: #ced5cc !important;
  color: #ffffff !important;
}

.medwell-blog
  .theme--light.v-pagination
  .v-pagination__navigation
  .theme--light.v-icon:hover {
  color: #ffffff;
} */

/* Global style - same as blog section in pharmacy page */
.medwell-blog .card-wrap:hover .v-image__image {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}

.medwell-blog .card-wrap {
  /* background-color: aqua; */
  max-width: 500px;
}

.medwell-blog .v-card {
  border-radius: 30px;
  /* padding: 0px 15px 20px 15px; */
  padding-bottom: 40px;
  position: relative;
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
  transition: -webkit-transform 0.8s ease-in-out;
  -o-transition: transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out, -webkit-transform 0.8s ease-in-out;
}

.medwell-blog .v-image {
  /* height: 346px; */
  border-radius: 30px;
}

.medwell-blog .v-card .card-date {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: #2b2b2b;
  color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  line-height: 1.2;
}

.medwell-blog .v-card .card-date .card-date-day {
  font-size: 40px;
}

.medwell-blog .v-card .card-date .card-date-month-year {
  font-size: 17px;
}

.medwell-blog .v-card__title,
.medwell-blog .v-card__text {
  padding-left: 40px;
  padding-right: 40px;
}

.medwell-blog .v-card__title {
  word-break: keep-all;
  font-family: "BigCaslon-Rom";
  font-size: 35px;
  font-weight: normal;
  line-height: 40px;
  padding-top: 40px;
}

.medwell-blog .v-card__text {
  font-family: "Open Sans", sans-serif;
  font-size: 19px;
  line-height: 30px;
  max-height: 90px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* position: relative; */
}

.medwell-blog .v-card__text::after {
  content: "...+more";
  display: inline-block;
  /* position: absolute;
  bottom: 0;
  right: 0; */
}

.medwell-blog .card-wrap:hover .v-card {
  -webkit-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  transform: translateY(-40px);
  -webkit-box-shadow: 0 8px 8px 5px rgba(22, 22, 26, 0.18);
  box-shadow: 0 8px 8px 5px rgba(22, 22, 26, 0.18);
}

.medwell-blog .card-wrap:hover .v-card__title {
  color: #a56c6a !important;
}

.medwell-blog .card-wrap:hover .card-date {
  background-color: #a56c6a;
}

.medwell-blog .v-card--link:before {
  background: transparent;
}

@media (max-width: 1903px) {
  .medwell-blog .v-card {
    padding-bottom: 25px;
  }

  .medwell-blog .v-card .card-date {
    width: 80px;
    height: 80px;
  }

  .medwell-blog .v-card .card-date .card-date-day {
    font-size: 35px;
  }

  .medwell-blog .v-card .card-date .card-date-month-year {
    font-size: 12px;
  }

  .medwell-blog .v-card__title,
  .medwell-blog .v-card__text {
    padding-left: 25px;
    padding-right: 25px;
  }
  .medwell-blog .v-card__title {
    font-size: 28px;
    padding-top: 25px;
    line-height: 35px;
  }

  .medwell-blog .v-card__text {
    font-size: 17px;
    line-height: 25px;
    max-height: 75px;
  }
}

@media (max-width: 1263px) {
  .medwell-blog .card-wrap {
    max-width: 400px;
  }
}

@media (max-width: 991.98px) {
  .medwell-blog .v-card__title {
    font-size: 25px;
  }

  .medwell-blog .v-card__text {
    font-size: 15px;
  }
}

@media (max-width: 959px) {
  .medwell-blog .v-card__title {
    line-height: 30px;
  }

  .medwell-blog .v-card__text {
    line-height: 20px;
    max-height: 60px;
  }
}

@media (max-width: 767.98px) {
  .medwell-blog .card-wrap:hover .v-card {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@media (max-width: 599px) {
  .medwell-blog .card-wrap {
    max-width: 80%;
  }

  .medwell-blog .v-card__title {
    line-height: 35px;
  }

  .medwell-blog .v-card__text {
    line-height: 25px;
    max-height: 75px;
  }
}

@media (max-width: 350px) {
  .medwell-blog .v-card .card-date {
    width: 60px;
    height: 60px;
  }

  .medwell-blog .v-card .card-date .card-date-day {
    font-size: 30px;
  }

  .medwell-blog .v-card .card-date .card-date-month-year {
    font-size: 8px;
  }

  .medwell-blog .v-card__title {
    font-size: 25px;
    padding-top: 25px;
    line-height: 30px;
  }

  .medwell-blog .v-card__text {
    font-size: 15px;
    line-height: 20px;
    max-height: 60px;
  }
}
</style>