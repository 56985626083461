import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primaryText: '#2B2B2B',
                secondaryText: '#404040',
                primaryColor: '#CED5CC',
                secondaryColor: '#F0F2F0',
                hoverColor: '#A56C6A'
            },
        }
    }
});
