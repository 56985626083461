import Vue from 'vue'
import VueRouter from 'vue-router'
import Pharmacy from '../views/Pharmacy.vue'
import Story from '../views/Story.vue'
import Doctors from '../views/Doctors.vue'
import Thankyou from '../views/Thankyou.vue'
import Blog from '../views/Blog.vue'
import BlogDetails from '../views/BlogDetails.vue'
import TermOfService from '../views/TermOfService.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Pharmacy',
    component: Pharmacy
  },
  {
    path: '/story',
    name: 'Story',
    component: Story
  },
  {
    path: '/doctors',
    name: 'Doctors',
    component: Doctors
  },
  {
    path: '/thankyou',
    name: 'Thankyou',
    component: Thankyou,
    meta: { hideNavigation: true }
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/blog/:blogId/details',
    component: BlogDetails,
    props: true,
  },
  {
    path: '/termofservice',
    name: 'TermOfService',
    component: TermOfService
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
