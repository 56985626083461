import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
import "./assets/css/style.css"
import "./assets/js/script.js"
// import LoadScript from "vue-plugin-load-script";

Vue.config.productionTip = false
// Vue.use(LoadScript);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
