<template>
  <div class="blog-details">
    <v-container class="px-10 pt-16">
      <v-img
        class="mx-auto"
        :src="require(`@/assets/img/blog/${blog.img_name}`)"
        :alt="img_alt"
      >
      </v-img>
      <h1 class="primaryText--text mt-10">{{ blog.title }}</h1>
      <v-row dense class="grey--text post-text">
        <div class="mr-4">
          <v-icon color="grey lighten-1" size="22px">mdi-account</v-icon>
          Authored by {{ blog.author }}
        </div>
        <div>
          <v-icon color="grey lighten-1" size="22px">mdi-calendar-month</v-icon>
          Posted on {{ blog.date }}
        </div>
      </v-row>

      <v-container
        v-html="blog.content"
        class="text-justify my-12 pa-0 section-text"
      ></v-container>
      <h2>References</h2>
      <ol>
        <li
          v-for="reference in blog.references"
          :key="reference.text"
          v-html="reference.text"
          class="reference-text"
        ></li>
      </ol>
    </v-container>
  </div>
</template>

<script>
// import $ from "jquery";
export default {
  props: ["blogId"],
  data() {
    return {
      blog: null,
      blogs: [
        {
          id: 1,
          img_name: "Antimicrobial Resistance.jpg",
          img_alt: "Antimicrobial Resistance: The Role of Healthcare Providers",
          title: "Antimicrobial Resistance: The Role of Healthcare Providers",
          author: "Dr. Kevin Ong, B.Sc., Pharm.D.",
          date: "27 Nov 2019",
          content:
            "<p>The World Health Organization (WHO) considers antibiotic resistance to be one of the major public health threats of this century.<sup>1</sup> Antibiotic resistance occurs when potentially life-threatening bacteria resists the action of the agent that is administered to fight against them. Resistance of these agents can be developed through a variety of mechanisms such as drug inactivation, alteration of binding sites or metabolic pathways, or reduced drug accumulation by the bacteria.<sup>2</sup> This can make the treatment of bacterial infections such as pneumonia or endocarditis very challenging, causing treatment delays and leaving providers with limited choices of antibiotics. Practices such as inappropriate antibiotic prescribing or use, patients failing to take antibiotics in accordance to proper directions of use, incomplete antibiotic courses or improper antibiotic disposal can all contribute to the emergence of drug-resistant bacteria. </p>" +
            "<p>In Malaysia, antibiotics are often inappropriately prescribed for self-limiting conditions such as the common cold, which poses a significant threat to the development of antibiotic resistance. The National Medical Care Survey (NMCS) 2014 revealed higher antibiotic prescribing rates in the private sectors compared to the public settings.<sup>3</sup> Several reasons such as patient perception and expectation, as well as financial incentives for clinics and pharmacies could also contribute to the widespread of antibiotic use. Beyond the prescribing step, it is also important to consider the other stages of the medication use process. Patients themselves are also potential contributors to the preventable evolution of resistant bugs, also known as “Superbug”, by not taking the antibiotics as directed, which may include missed doses, and incomplete courses of antibiotic use. This practice will eventually lead to the survival of the bacteria, and allow them to thrive and evolve into resistant strains of bacteria that in worst cases may be untreatable by antibiotics currently available on the market.</p>" +
            "<p>As healthcare providers, we want to provide the best care possible to patients through treatment regimens that are both safe and effective. Patient education also plays an essential part in clinical practice as most individuals in this country possess poor health literacy. Since the majority of patients are deficient in basic scientific knowledge to sufficiently grasp the mechanisms of bacterial infection and the severity of antibiotic resistance development, healthcare providers here would need to be able to translate those information into layman terms that most patients can comprehend. In addition to that, ensuring that the patients understand the proper use of the prescribed antibiotics is essential, and this can be done through counseling patients on taking the prescribed antibiotic as directed and educating them on the necessity to complete the entire course. Lastly, patients should also be counselled on the proper disposal of unused antibiotics as most prescriptions are dispensed by the unit box, thus some patients prescribed with shorter antibiotic courses would be left with extra antibiotics. Proper disposal of antibiotics should be strongly encouraged as studies have shown that inappropriately discarded antibiotics can accumulate in the soil and water supplies.<sup>4</sup></p>",
          references: [
            {
              text: "World Health Organization. Antimicrobial resistance: global report on surveillance 2014. Geneva: World Health Organization; 2014.",
            },
            {
              text: "Aslam B, Wang W, Arshad M et al. Antibiotic resistance: a rundown of a global crisis. <i>Infect Drug Resist</i>. 2018;Volume 11:1645-1658. doi:10.2147/idr.s173867",
            },
            {
              text: "Ab Rahman N, Teng C, Sivasampu S. Antibiotic prescribing in public and private practice: a cross-sectional study in primary care clinics in Malaysia. <i>BMC Infect Dis</i>. 2016;16(1). doi:10.1186/s12879-016-1530-2",
            },
            {
              text: "Larsson D. Antibiotics in the environment. <i>Ups J Med Sci</i>. 2014;119(2):108-112. doi:10.3109/03009734.2014.896438",
            },
          ],
        },
        {
          id: 2,
          img_name: "Antimicrobial Resistance.jpg",
          img_alt: "Antimicrobial Resistance: The Role of Healthcare Providers",
          title: "Anxxxance: The Role of Healthcare Providers",
          author: "XXXXg, B.Sc., Pharm.D.",
          date: "2XX9",
          content:
            "<p>XXXXXealth Organization (WHO) considers antibiotic resistance to be one of the major public health threats of this century.<sup>1</sup> Antibiotic resistance occurs when potentially life-threatening bacteria resists the action of the agent that is administered to fight against them. Resistance of these agents can be developed through a variety of mechanisms such as drug inactivation, alteration of binding sites or metabolic pathways, or reduced drug accumulation by the bacteria.<sup>2</sup> This can make the treatment of bacterial infections such as pneumonia or endocarditis very challenging, causing treatment delays and leaving providers with limited choices of antibiotics. Practices such as inappropriate antibiotic prescribing or use, patients failing to take antibiotics in accordance to proper directions of use, incomplete antibiotic courses or improper antibiotic disposal can all contribute to the emergence of drug-resistant bacteria. </p>" +
            "<p>In Malaysia, antibiotics are often inappropriately prescribed for self-limiting conditions such as the common cold, which poses a significant threat to the development of antibiotic resistance. The National Medical Care Survey (NMCS) 2014 revealed higher antibiotic prescribing rates in the private sectors compared to the public settings.<sup>3</sup> Several reasons such as patient perception and expectation, as well as financial incentives for clinics and pharmacies could also contribute to the widespread of antibiotic use. Beyond the prescribing step, it is also important to consider the other stages of the medication use process. Patients themselves are also potential contributors to the preventable evolution of resistant bugs, also known as “Superbug”, by not taking the antibiotics as directed, which may include missed doses, and incomplete courses of antibiotic use. This practice will eventually lead to the survival of the bacteria, and allow them to thrive and evolve into resistant strains of bacteria that in worst cases may be untreatable by antibiotics currently available on the market.</p>" +
            "<p>As healthcare providers, we want to provide the best care possible to patients through treatment regimens that are both safe and effective. Patient education also plays an essential part in clinical practice as most individuals in this country possess poor health literacy. Since the majority of patients are deficient in basic scientific knowledge to sufficiently grasp the mechanisms of bacterial infection and the severity of antibiotic resistance development, healthcare providers here would need to be able to translate those information into layman terms that most patients can comprehend. In addition to that, ensuring that the patients understand the proper use of the prescribed antibiotics is essential, and this can be done through counseling patients on taking the prescribed antibiotic as directed and educating them on the necessity to complete the entire course. Lastly, patients should also be counselled on the proper disposal of unused antibiotics as most prescriptions are dispensed by the unit box, thus some patients prescribed with shorter antibiotic courses would be left with extra antibiotics. Proper disposal of antibiotics should be strongly encouraged as studies have shown that inappropriately discarded antibiotics can accumulate in the soil and water supplies.<sup>4</sup></p>",
          references: [
            {
              text: "World Health Organization. Antimicrobial resistance: global report on surveillance 2014. Geneva: World Health Organization; 2014.",
            },
            {
              text: "Aslam B, Wang W, Arshad M et al. Antibiotic resistance: a rundown of a global crisis. <i>Infect Drug Resist</i>. 2018;Volume 11:1645-1658. doi:10.2147/idr.s173867",
            },
            {
              text: "Ab Rahman N, Teng C, Sivasampu S. Antibiotic prescribing in public and private practice: a cross-sectional study in primary care clinics in Malaysia. <i>BMC Infect Dis</i>. 2016;16(1). doi:10.1186/s12879-016-1530-2",
            },
            {
              text: "Larsson D. Antibiotics in the environment. <i>Ups J Med Sci</i>. 2014;119(2):108-112. doi:10.3109/03009734.2014.896438",
            },
          ],
        },
        /*Sheau Hui*/
        {
          id: 14,
          img_name: "Mental Health Illness is Not a Weakness.jpg",
          img_alt: "Mental Health Illness is Not a Weakness",
          title: "Mental Health Illness is Not a Weakness",
          author: "Dr. Sharon Lee, PharmD",
          date: "26 Sept 2021",
          content:
            "<p>Over the past decade, mental health disorders have become one of the most prevalent public health concerns all over the world. Mental health, as defined by the World Health Organization, includes a subjective well-being, perceived self-efficacy, autonomy, competence, inter-generational dependence, and self-actualization of one’s intellectual and emotional potential, among others.<sup>1</sup> </p>" +
            "<p>According to the Ministry of Health, mental disorders in Malaysia is estimated to be responsible for about 8.6% of total daily adjusted life years (DALYs), and the National Health Morbidity Survey in 2015 reported the prevalence of mental health issues in both adults and children at 29.2% and 12.1% respectively.<sup>2</sup> In addition, the director-general of the Ministry of Health, Datuk Dr. Noor Hisham Abdullah, mentioned in an interview two months ago that the National Health and Morbidity Survey 2017 revealed a trend of a concerning increase in suicidal tendency amongst youths aged between 13 and 17 in Malaysia had increased by 10% in 2017 compared to 7.9% in 2012.<sup>3</sup></p>" +
            "<p>In the Malaysian culture, mental health disorders are often viewed as the same as a psychiatric disorder, similar to illnesses such as bipolar disorders or schizophrenia. Therefore, a strong stigma revolves around mental health disorders, which causes many patients who suffers from a mental health disorder such as depression or anxiety to fight their battles alone. In this society, many still view mental health illnesses as a weakness rather than a true medical condition. It is this perception of mental health by the society as a whole that worsens the incidence and prevalence of mental health issues and suicide attempts in Malaysia.</p>" +
            "<p>Fortunately, mental health has been receiving more attention by the media and also is more openly discussed by the general public in the recent years. As a healthcare professional, it is important for us to realize the debilitating effects that a long-term mental health illness can have on an individual. Pharmacists, especially those who practice in community settings, should be able to recognize signs of mental health disorders. Since pharmacists are not professionals when it comes to psychiatry counseling, it is good practice for pharmacists to be able to refer these patients to the right channels that can help their condition. A good source of help in Malaysia would be the Mental Illness Awareness and Support Association (MIASA), also known as <i>Persatuan Kesedaran Dan Sokongan Pengakit Mental</i>, a mental health NGO based in Selangor. The objective of MIASA is to promote awareness on the importance of mental health and mental health issues and provide support for patients and caregivers through their various programs and support initiative.<sup>4</sup></p>",
          references: [
            {
              text: "WHO | The world health report 2001 - Mental Health: New Understanding, New Hope. Who.int. <a href='https://www.who.int/whr/2001/en/' target='_blank'>https://www.who.int/whr/2001/en/</a>. Published 2019. Accessed December 9, 2019.",
            },
            {
              text: "Mental Health Performance Report 2016. Ministry of Health Malaysia. <a href='http://www.moh.gov.my/moh/resources/Penerbitan/Laporan/Umum/Mental%20Healthcare%20Performance%20Report%202016.pdf' target='_blank'>http://www.moh.gov.my/moh/resources/Penerbitan/Laporan/Umum/Mental%20Healthcare%20Performance%20Report%202016.pdf</a>. Published 2019. Accessed December 9, 2019.",
            },
            {
              text: "Suicidal tendencies on the rise in Malaysia, says Health DG. The Star Online. <a href='https://www.thestar.com.my/news/nation/2019/10/10/suicidal-tendencies-on-the-rise-in-malaysia-says-health-dg' target='_blank'>https://www.thestar.com.my/news/nation/2019/10/10/suicidal-tendencies-on-the-rise-in-malaysia-says-health-dg</a>. Published 2019. Accessed December 9, 2019.",
            },
            {
              text: "Malaysia M. Mental Illness Awareness and Support Association MIASA. Miasa.org.my. <a href='https://miasa.org.my/about.html' target='_blank'>https://miasa.org.my/about.html</a>. Published 2019. Accessed December 9, 2019.",
            },
          ],
        },
        {
          id: 15,
          img_name: "Patient Counselling.jpg",
          img_alt: "Patient Counselling",
          title:
            "Patient Counselling: A Refresher for Pharmacists in Malaysia.",
          author: "Dr. Sharon Lee, PharmD",
          date: "26 Sept 2021",
          content:
            "<p>Pharmacist as a profession was traditionally viewed as nothing more than a “pill counter”. Today, many continue to perceive this profession the same way although the profession has substantially evolved over the past few decades with the incorporation of clinical practices, including patient medication counseling as one of the core duties of a pharmacist. Patient counselling is an acquired skill that is taught in most pharmacy schools; however, the proper practice of this skill is not commonly observed in the majority of pharmacy settings in Malaysia.</p>" +
            "<p>In the retail settings, pharmacists are the central of patient contact. Not only is the accuracy of prescription dispensing important, ensuring that patients understand the proper use and also the adverse effects associated with their medication therapy is just as critical. In addition to that, the sales of supplements is essentially the main source of steady profit for most retail pharmacies, and customers who purchase any supplement at all should be counselled on the proper use and also the appropriateness of those supplements regardless of profit as it involves the ethics of pharmacy practice.</p>" +
            "<p>There is not one patient counseling method that is absolutely the correct way nor is there a completely wrong approach to it. However, it is good practice for pharmacists to revisit patient counseling techniques every now and then to refresh our memories on this particular area. A reputable source of guidance on patient counseling would be the ASHP Guidelines on Pharmacist-Conducted Patient Education and Counseling. This document was specifically developed to provide guidance to pharmacists in order to conduct effective patient education and counseling. A simple counseling tool dubbed “DRUG” was developed by Dr. Steve Leuck (PharmD), the founder of AudibleRx, to guide patient medication counseling.<sup>1</sup> The “D” in the acronym stands for Dosage, where pharmacists discuss the dose of the medication, its uses, how it should be taken, and also missed dose guidance.<sup>1</sup> The “R” in the acronym stands for Results, which represents patient’s expectations out of taking a certain medication prescribed. The mechanism of the drug can be discussed in this section as well.<sup>1</sup> Next is the “U” in the acronym where pharmacists educate the patient on the <i>Underlying Issues</i> of taking the medication, which should include warnings and/or precautions, patient specific allergies that may defer the use of this medication, and any other pertinent issues.<sup>1</sup> Lastly, the acronym “G” represents <i>General Information</i> where pharmacists should assess patient’s understanding of all of the above mentioned information.<sup>1</sup> Medication storage, refills, and proper disposal of unused medication can also be discussed before ending the counseling session.</p>" +
            "<p>Another patient counseling technique worth mentioning would be Motivational Interviewing (MI), which involves the “5 R’s”: (1) Relevance, (2) Risks, (3) Rewards, (4) Roadblocks, and (5) Repetition.<sup>2</sup> This technique is especially useful for patients who are current smokers and may present with some reluctance to smoking cessation. In short, “Relevance” is where a healthcare provider would discuss the reasons as to why quitting smoking is important to the patient; “Risks” would involve discussions on the negative consequences of continuing the current habits; “Rewards” is when benefits of smoking cessation is discussed; “Roadblocks” is where healthcare providers would assess patient’s barriers to smoking cessations; and “Repetition” is the practice of repeating MI every time that the patient visits the healthcare setting.<sup>2</sup></p>" +
            "<p>The importance of the proper practice of patient counseling applies strongly to community pharmacists as many patients rely on these pharmacists for medication information and education.<sup>1</sup> As such, it is essential for pharmacists to stay updated on medication information and the understanding of important counseling topics with every medication dispense. The upkeep of our skills as patient educators is ultimately every pharmacist’s duty as we are the ones conveying such scientific information in a suitable manner to our patients.</p>",
          references: [
            {
              text: "Leuck S. Patient Medication Counseling Tool for Pharmacists. Pharmacy Times. <a href='https://www.pharmacytimes.com/contributor/steve-leuck-pharmd/2015/02/patient-medication-counseling-tool-for-pharmacists' target='_blank'>https://www.pharmacytimes.com/contributor/steve-leuck-pharmd/2015/02/patient-medication-counseling-tool-for-pharmacists</a>. Published 2019. Accessed December 29, 2019.",
            },
            {
              text: "Lindson-Hawley N, Thompson T, Begh R. Motivational interviewing for smoking cessation. <i>Cochrane Database of Systematic Reviews</i>. 2015. doi:10.1002/14651858.cd006936.pub3",
            },
            {
              text: "Pilnick A. “Patient counselling” by pharmacists: four approaches to the delivery of counselling sequences and their interactional reception. <i>Soc Sci Med</i>. 2003;56(4):835-849. doi:10.1016/s0277-9536(02)00082-5",
            },
          ],
        },
        {
          id: 16,
          img_name:
            "What a Pharmacist Should Know About Pediatric Pharmacy.jpg",
          img_alt: "What a Pharmacist Should Know About Pediatric Pharmacy",
          title: "What a Pharmacist Should Know About Pediatric Pharmacy",
          author: "Dr. Sharon Lee, PharmD",
          date: "27 Sept 2021",
          content:
            "<p>The pediatric population is a vulnerable group in terms of medication safety, and the saying that most pediatric pharmacists go by is “kids are not just little adults.” Dealing with pediatric patients requires extra attentiveness from healthcare professionals as medication dosing for this population depends on their age groups and weight ranges, and the pharmacokinetics in children are completely different from adults and can vary throughout childhood.<sup>1</sup></p>" +
            "<p>Medication dosing ranges for the neonates and pediatric populations are often broken down by age and weight ranges, so it is important for pharmacists to be familiar with the breakdown of ages. There is some variance in the breakdown of ages but it is generally agreed upon that the first four weeks of life are neonates, infants are from 29 days of age to 12 months, children are categorized from ages 1 to 12 years, adolescents are from 13 to 17 years, and young adults are from 18 to 24 years of age.<sup>1</sup> When receiving a pediatric prescription, it is important to always check the appropriateness of the dose by breaking it down to mg/kg/dose and mg/kg/day, and remember that most medications are max at adult doses.<sup>1</sup> In adults, ideal body weight or adjusted body weight are often used for pharmaceutical calculations. When it comes to children, there is no universal rule to determine if they need to have their body weight adjusted or what their body mass index should be at different ages. The Centers for Disease Control and Prevention growth charts available in the Paediatric Pharmacy Services Guideline by the Pharmaceutical Services Division Ministry of Health Malaysia are helpful tools in determining what weight to use for calculations when it comes to children.<sup>2,3</sup></p>" +
            "<p>Pharmacokinetics vary throughout childhood, and so pharmacists should always research pharmacokinetics for each medication used in children whenever possible. In addition to that, creatinine clearance (CrCL) is also calculated differently for children, and the Schwartz Equation is usually used for children as opposed to the Cockcroft-Gault and Jeliffe equations commonly used for adults, but these equations can still be used once the kids are in their later teens and taller than 5 feet.<sup>1</sup> Furthermore, vital signs are also different in pediatric patients where the blood pressure, heart rate, and respiratory rate ranges vary based on age, gender, and height.<sup>1,2</sup> There are many references that can be used for the vital signs ranges in children such as the Pediatric Advanced Life Support guidelines from the American Heart Association (AHA).</p>" +
            "<p>Some challenges that healthcare providers might face when it comes to providing care to pediatric patients include issues with communication and kids refusing to take their medications. In pediatric pharmacy, we as healthcare providers would often have to interact with the parents instead of the children. It is important for us to realize that children are people as well, and their involvement in his or her own care should be assessed and understood. When it comes to taking medications, some kids are able to swallow pills at a very young age, while others have trouble swallowing pills into adulthood. It can be helpful to use capsules or tablets that can be opened or crushed and put into formula, milk, applesauce, or pudding for easier consumption.<sup>1</sup> Some medications can also be flavored as well.<sup>1</sup></p>" +
            "<p>It is difficult for pharmacists to remember everything related to pediatric healthcare. Therefore, references can be very helpful and the go-to references for pediatric pharmacy include NeoFax (a subset of Micromedex<sup>®</sup>) and Lexicomp<sup>®</sup>. In addition to that, the <i>Paediatric Pharmacy Services Guideline</i> that is available on the Pharmaceutical Services Division Ministry of Health Malaysia website is accessible by all pharmacists in Malaysia. Lastly, there is always primary literature whenever guidelines and tertiary databases are insufficient.</p>",
          references: [
            {
              text: " Simpson N. What Every Pharmacist Should Know About Pediatric Pharmacy. Pharmacy Times. <a href='https://www.pharmacytimes.com/contributor/nova-simpson-pharmd/2017/11/what-every-pharmacist-should-know-about-pediatric-pharmacy' target='_blank'>https://www.pharmacytimes.com/contributor/nova-simpson-pharmd/2017/11/what-every-pharmacist-should-know-about-pediatric-pharmacy</a>. Published 2017. Accessed February 24, 2020.",
            },
            {
              text: "<i>Paediatric Pharmacy Services Guideline</i>. 1st ed. Petaling Jaya: Pharmaceutical Services Division Ministry of Health Malaysia; 2015. <a href='https://www.pharmacy.gov.my/v2/sites/default/files/document-upload/paediatric-pharmacy-services-guideline.pdf' target='_blank'>https://www.pharmacy.gov.my/v2/sites/default/files/document-upload/paediatric-pharmacy-services-guideline.pdf</a>. Accessed February 24, 2020.",
            },
            {
              text: "Growth Charts - Clinical Growth Charts. Cdc.gov. <a href='https://www.cdc.gov/growthcharts/clinical_charts.htm' target='_blank'>https://www.cdc.gov/growthcharts/clinical_charts.htm</a>. Accessed February 24, 2020.",
            },
          ],
        },
        {
          id: 17,
          img_name: "Polypharmacy.jpg",
          img_alt: "Polypharmacy",
          title:
            "Polypharmacy: Pharmacist’s Importance in Medication Management",
          author: "Dr. Kevin Ong, B. Sc., PharmD",
          date: "27 Sept 2021",
          content:
            "<p>Medications are used as pharmacotherapy to treat or prevent diseases. Most do not think much of this seemingly simple solution to an ailment, but it poses an issue when there are multiple chronic conditions to treat in one patient, which then leads to the concurrent use of multiple medications. This situation is defined as polypharmacy, where a patient is on five or more medications concurrently.<sup>1</sup> Although the general rule starts at five concurrent medications, experts tend to evaluate the magnitude of potential for harm when defining polypharmacy regardless of whether a patient is on five or more medications at once.<sup>1</sup></p>" +
            "<p>Polypharmacy is common amongst the older population as the presence of multimorbidity is higher in this population, which leads to the need for multiple medications in order to control their symptoms, and to prevent disease complications and progression.<sup>1</sup> The reason why polypharmacy poses a concern in healthcare is its association with increased morbidity and mortality due to potentially inappropriate drug use, especially in the elderly population.<sup>2</sup> According to the Ministry of Health Malaysia and the United Nations, elderly population is expected to increase from 11% in 2013 to 22% worldwide by the year 2050.<sup>2</sup> In Malaysia, the three most common chronic conditions are high cholesterol, hypertension and diabetes.<sup>3</sup> The most frequently used therapeutic drug class is lipid-regulating agents such as the HMG-CoA Reductase Inhibitors<sup>2</sup>, also known as the “Statins”,  which are well known for their major drug-drug interactions issue due to their metabolism pathway through cytochrome P450 (CYP) 3A, and this issue is mostly seen with simvastatin, lovastatin and atorvastatin, which are some of the most commonly prescribed Statins.</p>" +
            "<p>Besides pharmaceutical drugs, supplement use also significantly adds to polypharmacy, increasing problems with inappropriate drug use and potential drug-drug interactions. In the retail pharmacy setting, it is common practice for pharmacists to recommend supplements that would have health benefits to patients. While these recommendations are made in hopes to improve the patients’ health, pharmacists must not overlook the potentials for any drug-drug or drug-food interactions that may occur between the pharmaceutical drugs and the supplements that are recommended to the patients. It is good practice to evaluate the potential for any inappropriate supplement use by going through the ingredients of the recommended product and run an interaction check using clinical databases. As for the older population in which polypharmacy is most prevalent, pharmacists should recall the American Geriatrics Society (AGS) BEERS criteria that is commonly used in the care of older adults >65 years old across all healthcare settings.<sup>3</sup> The AGS BEERS criteria is a useful tool that addresses potentially inappropriate medication use in older adults, including guides on inappropriate medication combinations and harmful interactions.<sup>3</sup></p>" +
            "<p>The issue with polypharmacy ultimately ties back to medication safety. Therefore, in order to ensure that patients are using their medications and supplements safely, it is a pharmacist’s duty to review the appropriateness of their medications, including the evaluation of the patients’ allergy and intolerance profiles. When it comes to polypharmacy management, collaboration with the patient is helpful in shaping their plan of care.</p>",
          references: [
            {
              text: "Ong G, Page A, Caughey G, Johns S, Reeve E, Shakib S. Clinician agreement and influence of medication-related characteristics on assessment of polypharmacy. <i>Pharmacol Res Perspect.</i> 2017;5(3):e00321. doi:10.1002/prp2.321",
            },
            {
              text: "Lim L, McStea M, Chung W et al. Prevalence, risk factors and health outcomes associated with polypharmacy among urban community-dwelling older adults in multi-ethnic Malaysia. <i>PLoS ONE</i>. 2017;12(3):e0173466. doi:10.1371/journal.pone.0173466",
            },
            {
              text: "Fick D, Semia T, Steinman M et al. American Geriatrics Society 2019 Updated AGS Beers Criteria® for Potentially Inappropriate Medication Use in Older Adults. <i>J Am Geriatr Soc</i>. 2019;67(4):674-694. doi:10.1111/jgs.15767",
            },
          ],
        },
        {
          id: 18,
          img_name: "Medication Safety in Pregnancy and Lactation.jpg",
          img_alt: "Medication Safety in Pregnancy and Lactation",
          title: "Medication Safety in Pregnancy and Lactation",
          author: "Dr. Sharon Lee, PharmD",
          date: "28 Sept 2021",
          content:
            "<p>As a pharmacist, being asked to give advice about medication use during pregnancy or lactation can be quite intimidating. It is common for patients and even other healthcare professionals to enquire pharmacists on the compatibility of certain medications with pregnancy or breastfeeding, and it is often up to the pharmacist’s clinical judgment when giving a recommendation as many literatures on medication use or safety during pregnancy or lactation may present conflicting points that are confusing. </p>" +
            "<p>For pregnant mothers, the main concern with the use of medications, prescriptions or over-the-counter medications, is the drug transfer across the placenta which may be of harm to the growing fetus.<sup>1</sup> Many pregnant women take at least one medication during their pregnancy, and for those with comorbidities such as high blood pressure, asthma or epilepsy, their use of medications during pregnancy would at least double.<sup>2</sup> In addition to that, existing medical conditions of a pregnant woman may change during the course of her pregnancy, and this poses a need for pharmacists to be well versed on the safety profile of medications in order to provide the most optimal recommendations to these patients.<sup>2</sup> In practice, there are several useful and reliable sources that can be utilized to aid pharmacists in the determination of medication safety profile for pregnant women.</p>" +
            "<p>For breastfeeding mothers, they would always want to ensure that the breastmilk that they are producing for their babies are safe for them to consume. Fortunately, most medications are compatible with breastfeeding.<sup>3</sup> However, several factors such as the lipophilicity and the molecular size of the medications can affect the permeability of those drugs into the mother’s breast milk.<sup>3</sup> Some general guidelines that can help a healthcare provider in choosing the right drugs for breastfeeding mothers include (1) the bioavailability of the drug – low bioavailability generally means that not a significant amount would cross into the breast milk; (2) determine milk to plasma ratio – a high ratio would be more than 1, however, a high milk to plasma ratio does not necessarily mean that the absolute amount of drug entering the milk will be high if the maternal plasma level of the medication is very small (i.e. propranolol); (3) choose medications with short half-life – these drugs should clear out of the mother’s circulation faster and reduces accumulation within the infant’s plasma; (4) choose drugs with high protein binding – these drugs will tend to stay in the mother’s circulation, thus reduces the transfer to the breast milk.<sup>3</sup></p>" +
            "<p>One of the most commonly cited references for use in the assessment of medication safety profiles for pregnancy and lactation is the book “Drugs in Pregnancy and Lactation: A Reference Guide to Fetal and Neonatal Risk” and some may know it as the “Briggs’ Book on Drugs in Pregnancy and Lactation”. This book has long been a staple in pharmacy practice for the safe medication use in pregnancy and lactation, and it is usually updated every three years. Another reliable source useful for medication use in lactating mothers is LactMed<sup>®</sup>, which is a free online database that provides information on medications that are compatible with breastfeeding mothers. Other good sources of reference are tertiary databases such as the IBM Micromedex<sup>®</sup> and Lexicomp<sup>®</sup>, which are mostly only accessible via paid subscriptions. Tertiary databases are helpful as they provide comprehensive information on medications, including useful information on the medications’ safety profiles even for special populations.</p>" +
            "<p>While the use of references is helpful in deciding the safe use of medications in pregnant or lactating women, it is also important for pharmacists to realize that the information presented in these references and databases are not meant to be a substitute for a pharmacist’s professional judgment. Recommendations should be made after adequate research and assessment of risks versus benefits of the medication use, and each recommendation should always be individualized.</p>",
          references: [
            {
              text: "Burkey B, Holmes A. Evaluating Medication Use in Pregnancy and Lactation: What Every Pharmacist Should Know. <i>The Journal of Pediatric Pharmacology and Therapeutics</i>. 2013;18(3):247-258. doi:10.5863/1551-6776-18.3.247",
            },
            {
              text: "Lim H. Pharmacists: How do you decide if a medication is safe for pregnant mothers?. MIMS News. <a href='https://today.mims.com/pharmacists--how-do-you-decide-if-a-medication-is-safe-for-pregnant-mothers-' target='_blank'>https://today.mims.com/pharmacists--how-do-you-decide-if-a-medication-is-safe-for-pregnant-mothers-</a>. Published 2016. Accessed January 27, 2020.",
            },
            {
              text: "Lim H. Pharmacists: How to decide if a medication is safe for breastfeeding mothers. MIMS News. <a href='https://today.mims.com/pharmacists--how-to-decide-if-a-medication-is-safe-for-breastfeeding-mothers' target='_blank'>https://today.mims.com/pharmacists--how-to-decide-if-a-medication-is-safe-for-breastfeeding-mothers</a>. Published 2016. Accessed January 27, 2020.",
            },
            {
              text: "Briggs G, Freeman R. <i>Drugs In Pregnancy And Lactation</i>. 11th ed. Wolters Kluwer; 2017.",
            },
          ],
        },
        {
          id: 19,
          img_name: "The Role of a Pharmacist in Public Health.jpg",
          img_alt: "The Role of a Pharmacist in Public Health",
          title: "The Role of a Pharmacist in Public Health",
          author: "Dr. Sharon Lee, PharmD",
          date: "28 Sept 2021",
          content:
            "<p>Pharmacists are not formally recognized as a profession that plays a role in the public health work force, unlike public health physicians, nurses, and even nutritionists. Although the public health role of a pharmacist has yet to be clearly defined, it is irrefutable that pharmacists, especially those practicing in the community settings, play an important role in public health outreach as health educators. Given pharmacists’ accessibility that is unique amongst health care professionals, a large window of opportunity to provide public health services is created.</p>" +
            "<p>In light of the recent high-profile Wuhan novel coronavirus (2019-nCoV) outbreak that prompted the lockdown of at least 10 cities in China’s Hubei province,<sup>1</sup> Malaysia’s Health Ministry has been on high alert in preventing the spread of this virus into our country. However, there has been four confirmed cases of the 2019-nCoV in the country as of 26<sup>th</sup> of January 2020.<sup>2</sup> Although this novel coronavirus outbreak in China has yet to be declared a global public health emergency, the world is on high alert and most countries are taking public health measures to prevent the spread of this virus into their respective countries.</p>" +
            "<p>Coronaviruses (CoV) is an umbrella term used for a large family of viruses that cause illnesses ranging from the common cold to severe diseases such as the infamous Severe Acute Respiratory Syndrome (SARS-CoV) and Middle East Respiratory Syndrome (MERS-CoV).<sup>3</sup> When a new strain of CoV is found that has not been previously identified in humans, such virus is dubbed as a novel coronavirus (nCoV), just like the Wuhan novel coronavirus.<sup>3</sup> Coronaviruses are zoonotic, which means that they transmit between animals and human beings.<sup>3</sup> Currently, there are a number of coronaviruses circulating in animals that have not yet infected humans.<sup>3</sup></p>" +
            "<p>As the news continue to report more and more cases of 2019-nCoV with rising mortality rate, the people of Malaysia have started to take the initiative to actively participate in personal protection against this virus. Most pharmacies are sold out of face masks and hand sanitizers, which shows that the volume of people visiting the pharmacies are on the rise during this time. Therefore, it is crucial for pharmacists to recognize the common signs of CoV infection, which include respiratory symptoms, fever, cough, shortness of breath, and breathing difficulties.<sup>3</sup> As the infection progresses in severity, the virus can lead to pneumonia, severe acute respiratory syndrome, kidney failure and even death.<sup>3</sup> Pharmacists, whether in hospital or retail settings, must be on a look out for any of these symptoms in their stream of customers, and must make an effort to refer patients exhibiting any of the above symptoms to the nearest hospital.</p>" +
            "<p>In addition, pharmacists can take on an active role in educating customers or patients on proper ways to prevent infection spread. Standard recommendations by the WHO include regular hand washing, covering mouth and nose when coughing and sneezing, thoroughly cooking meat and eggs, and avoiding close contact with anyone showing symptoms of respiratory illness such as coughing and sneezing.<sup>3</sup> Protecting the public health is every health care professional’s duty and so pharmacists should take on an active role in patient education during this critical period.</p>",
          references: [
            {
              text: "At Least 10 Chinese Cities on Lockdown; 830 Confirmed Coronavirus Cases Across Country. Voice of America. <a href='https://www.voanews.com/science-health/least-10-chinese-cities-lockdown-830-confirmed-coronavirus-cases-across-country' target='_blank'>https://www.voanews.com/science-health/least-10-chinese-cities-lockdown-830-confirmed-coronavirus-cases-across-country</a>. Published 2020. Accessed January 20, 2020.",
            },
            {
              text: "Wuhan virus: Fourth victim confirmed in Malaysia. The Star Online. <a href='https://www.thestar.com.my/news/nation/2020/01/26/wuhan-virus-fourth-victim-confirmed-in-malaysia' target='_blank'>https://www.thestar.com.my/news/nation/2020/01/26/wuhan-virus-fourth-victim-confirmed-in-malaysia</a>. Published 2020. Accessed January 26, 2020.",
            },
            {
              text: "Coronavirus. World Health Organization. <a href='https://www.who.int/health-topics/coronavirus' target='_blank'>https://www.who.int/health-topics/coronavirus</a>. Accessed January 20, 2020.",
            },
            {
              text: "The Role of the Pharmacist in Public Health. Apha.org. <a href='https://www.apha.org/policies-and-advocacy/public-health-policy-statements/policy-database/2014/07/07/13/05/the-role-of-the-pharmacist-in-public-health' target='_blank'>https://www.apha.org/policies-and-advocacy/public-health-policy-statements/policy-database/2014/07/07/13/05/the-role-of-the-pharmacist-in-public-health</a>. Published 2006. Accessed January 21, 2020.",
            },
          ],
        },
        {
          id: 20,
          img_name: "Medication Management during Ramadan.jpg",
          img_alt: "Medication Management during Ramadan",
          title: "Medication Management during Ramadan",
          author: "Dr. Sharon Lee, PharmD",
          date: "29 Sept 2021",
          content:
            "<p>Ramadan is the most holy month of the year in the Islamic culture. Practicing Muslims observe the month of Ramadan through fasting from dawn to sunset, abstinence from pleasures and prayers. Fasting hours in Malaysia usually range from 11 hours to 16 hours, and Ramadan this year will begin on April 24<sup>th</sup>, 2020.</p>" +
            "<p>Fasting during Ramadan is generally mandatory for all adult Muslims who are physically healthy with a sound mind. As for those who are sick, travelling, pregnant or breastfeeding, and for women who are menstruating, they can make up for their missed fasts whenever they are able to. Ramadan is the greatest Muslim celebration of the year and many will have a strong desire to participate in all aspects of this sacred celebration and will choose to fast even if it puts their own health at risk. While most can safely fast without jeopardizing their health, some populations such as the elderly and those with chronic conditions may be affected.</p>" +
            "<p>For health care professionals, caring for patients who celebrate and fast during the month of Ramadan presents a unique challenge. Patients who are on chronic medications will require adjustments to their medication schedule and/or dosage to accommodate the fasting period during Ramadan. The changes in dosing frequency and dosages of these medications can be tricky, especially for patients on diabetic medications.</p>" +
            "<p>Although the consumption of medications is affected during the fasting month, the use of the following medications will not nullify a fast:<sup>5</sup></p>" +
            "<ul><li>Eye and ear drops</li><li>Vaginal pessaries and washes</li><li>Injectables through the skin, muscle, joints or veins (excluding intravenous feeds)</li><li>Oxygen and anesthetic gases</li><li>Sublingual nitroglycerin tablets</li><li>Mouthwashes, gargles or oral sprays (without swallowing)</li><li>Topicals such as creams and ointments</li></ul><br />" +
            "<p>Suggested regimens for several chronic conditions during Ramadan, including Parkinson’s disease, thyroid disorders, and cardiac diseases are available mainly through primary literatures. As a general guideline to medication management during the fasting month of Ramadan, medications that are taken multiple times a day should be consolidated and switched to long-acting formulations with dosing frequency of once or twice daily whenever possible. </p>" +
            "<p>Many patients with Parkinson’s disease require multiple administrations of dopamine-replacement medication every day.<sup>6</sup> A general approach to managing these patients during Ramadan is to switch the patient’s treatment to an equivalent dose of a dopamine agonist that is administered once daily or via transdermal patch.<sup>6</sup> </p>" +
            "<p>As for thyroid disorders, patients with mild to moderate symptoms of hyperthyroidism can safely fast, and the preferred oral antithyroid medication is methimazole which can be taken once or twice daily during Ramadan.<sup>7</sup> Hypothyroid patients can take their thyroxine at bedtime instead of half an hour before <i>sahur</i> (dawn).<sup>7</sup> </p>" +
            "<p>Patients with hypertension or diabetes will certainly require closer monitoring during Ramadan. Health care providers should educate patients with hypertension to avoid dehydration, monitor their blood pressure regularly and to watch for signs of hypotension such as dizziness.<sup>1,4</sup> </p>" +
            "<table>" +
            "<tr><th>General Recommendations for Cardiac Patients<sup>1,2,3</sup></th></tr>" +
            "<tr><td><ul><li>Management of cardiac patients’ medications should be personalized</li><li>Diuretics should be avoided or at least reduce the doses, especially in hot weather and it should be taken after <i>iftar</i> (dusk)</li><li>Once-daily medication that is also long-acting is preferred</li><li>Patients with uncontrolled hypertension and are on multiple medications are advised to avoid fasting</li><li>Avoid high salt intake, and to take on a low-salt and low-fat diet</li></ul></td></tr>" +
            "</table><br />" +
            "<p>Fasting can increase the risk of hypoglycemia and diabetic ketoacidosis in patients with diabetes, and close monitoring of blood glucose levels can help avoid severe hypoglycemia.<sup>1</sup> Clinical guidelines on diabetic medication adjustments are available through Ministry of Health Malaysia.</p>" +
            "<p>Management of Type 2 Diabetes during Ramadan:</p>" +
            "<table>" +
            "<tr><th colspan='2'>Oral Hypoglycemics<sup>5,8,9,10</sup></th></tr>" +
            "<tr><td style='width:30%'>Metformin</td><td style='width:70%'><ul><li>No evidence available for the incidence of hypoglycemia in patients on metformin and fasting</li><li>Fasting patients on three-times-a-day regimen should:<ul><li>2/3 total daily dose before sunset meal</li><li>1/3 total daily dose before predawn meal</li></ul></li><li>Patients on Modified Release Metformin daily should take their dose <u>in the evening</u> rather than in the morning</li></ul></td></tr>" +
            "<tr><td style='width:30%'>Thiazolidinediones<br />DPP-4 Inhibitors<br />GLP-1 Analogues<br />SGLT2 Inhibitors<br />Alpha-glucosidase Inhibitors (AGI)</td><td style='width:70%'><ul><li>Limited experience with their use during Ramadan</li><li>Low risk of hypoglycemia</li><li>No dose adjustment needed</li></ul></td></tr>" +
            "<tr><td style='width:30%'>Sulfonylureas</td><td style='width:70%'><ul><li>Use with caution by fasting patients due to risk of hypoglycemia</li><li>Short-acting agents such as glimepiride and glipizide may be better choices</li><li>Avoid glibenclamide</li><li>Chlorpropamide is absolutely contraindicated</li><li>A once-a-day regimen should be taken <u>in the evening</u> with the heavier meal</li><li>For those on twice-a-day regimen, morning dose should be halved</li><li>If the risk of hypoglycemia is high, consider switching to DPP-4 inhibitors</li></ul></td></tr>" +
            "</table><br /><br />" +
            "<table>" +
            "<tr><th colspan='2'>Insulin Dosage Adjustments During Ramadan<sup>5,8,9,10</sup></th></tr>" +
            "<tr><td style='width:50%; text-align: center;'>Insulin Regimen</td><td style='width:50%; text-align: center;'>Adjustment</td></tr>" +
            "<tr><td style='width:50%'>Basal insulin only</td><td style='width:50%'><ul><li>Should be taken at bedtime or after <i>iftar</i> (dusk) meals</li><li>Dose reduction may be needed if there is a risk of daytime hypoglycemia</li></ul></td></tr>" +
            "<tr><td style='width:50%'>Premixed insulin (once daily)</td><td style='width:50%'><ul><li>Administer usual dose at <i>iftar</i> (dusk)</li></ul></td></tr>" +
            "<tr><td style='width:50%'>Premixed insulin (twice daily)</td><td style='width:50%'><ul><li><u>Reverse the doses</u> – Morning dose should be administered at <i>iftar</i> (dusk) and evening dose at <i>sahur</i> (dawn)</li><li>Insulin dose at sahur (dawn) should be reduced by 20-50% to prevent daytime hypoglycemia</li><li>Alternatively, switch to short/rapid acting insulin</li></ul></td></tr>" +
            "<tr><td style='width:50%'>Basal-bolus insulin:<br /><br />Basal insulin<br /><br />Bolus/Prandial insulin</td><td style='width:50%'><ul><li>Should be taken at bedtime or after <i>iftar</i> (dusk) meals</li><li>Dose reduction may be needed if there is a risk of daytime hypoglycemia</li></ul><br /><p style='margin-left:20px;'><i>Sahur</i> – Usual pre-Ramadan breakfast or lunch dose</p><p style='margin-left:20px;'>Lunch – omit dose</p><p  style='margin-left:20px;'><i>Iftar</i> – Usual pre-Ramadan dinner dose; may increase dose if needed.</p></td></tr>" +
            "<tr><td style='width:50%'>Insulin pump</td><td style='width:50%'><ul><li>Basal insulin rate may require up to 20% reduction</li><li>Prandial bolus insulin continues according to personalized insulin-to-carbohydrate ratio (ICR)</li></ul></td></tr>" +
            "</table><br /><br />" +
            "<p>As a health care provider, pharmacists play a crucial role in helping Muslims adjust their medications during Ramadan given our expertise in medication management. Pharmacists should be more proactive in counselling patients and offer to review their medications prior to, during, and after Ramadan. When appropriate, pharmacists should take the initiative to adjust patients’ medications, including the dosing frequency, the dose or dosage form of the medication and even the medication itself. Keep in mind that the safety of fasting and changes to any pharmacotherapy should be given special consideration while serving Muslim patients who observe fasting during the sacred month of Ramadan.</p>",
          references: [
            {
              text: "Chamsi-Pasha H, Chamsi-Pasha M. The cardiac patient in Ramadan. <i>Avicenna J Med</i>. 2016;6(2):33. doi:10.4103/2231-0770.179547",
            },
            {
              text: "Beshyah S, Fathalla W, Saleh A et al. Mini-symposium: Ramadan fasting and the medical patient: An overview for clinicians. <i>Ibnosina Journal of Medicine and Biomedical Sciences</i>. 2010;2(5):240. doi:10.4103/1947-489x.211004",
            },
            {
              text: "Table 10, Oral antihypertensive drugs* - The Seventh Report of the Joint National Committee on Prevention, Detection, Evaluation, and Treatment of High Blood Pressure - NCBI Bookshelf. NCBI. <a href='https://www.ncbi.nlm.nih.gov/books/NBK9626/table/A66/' target='_blank'>https://www.ncbi.nlm.nih.gov/books/NBK9626/table/A66/</a>. Published 2020. Accessed April 19, 2020.",
            },
            {
              text: "Grindrod K, Alsabbagh W. Managing medications during Ramadan fasting. <i>Canadian Pharmacists Journal / Revue des Pharmaciens du Canada</i>. 2017;150(3):146-149. doi:10.1177/1715163517700840",
            },
            {
              text: "Gilani A. Medicines management during Ramadan. <i>Pharm J</i>. 2011. <a href='https://www.pharmaceutical-journal.com/download?ac=1065099' target='_blank'>https://www.pharmaceutical-journal.com/download?ac=1065099</a>. Accessed April 17, 2020.",
            },
            {
              text: "Damier P, Al-Hashel J. Recommendations for the Treatment of Patients With Parkinson Disease During Ramadan. <i>JAMA Neurol</i>. 2017;74(2):233. doi:10.1001/jamaneurol.2016.4291",
            },
            {
              text: "Raza S, Unnikrishnan A, Ahmad J et al. Thyroid diseases and Ramadan. <i>Indian J Endocrinol Metab</i>. 2012;16(4):522. doi:10.4103/2230-8210.98001",
            },
            {
              text: "Wan Bebakar W. Clinical Practice Guidelines: Management of Type 2 Diabetes Mellitus (5th Edition) - Diabetes in Ramadan. Presentation presented at the: 2015; Hospital Universiti Sains Malaysia Kota Baru.",
            },
            {
              text: "Al-Arouj M, Assaad-Khalil S, Buse J et al. Recommendations for Management of Diabetes During Ramadan: Update 2010. <i>Diabetes Care</i>. 2010;33(8):1895-1902. doi:10.2337/dc10-0896",
            },
            {
              text: "Ibrahim M, Abu Al Magd M, Annabi F et al. Recommendations for management of diabetes during Ramadan: update 2015. <i>BMJ Open Diabetes Res Care</i>. 2015;3(1):e000108. doi:10.1136/bmjdrc-2015-000108",
            },
          ],
        },
        {
          id: 21,
          img_name: "It’s time to talk about sexual health.jpg",
          img_alt: "It’s time to talk about sexual health",
          title: "It’s time to talk about sexual health.",
          author: "Dr. Sharon Lee, PharmD",
          date: "29 Sept 2021",
          content:
            "<p>Sexual health is a sense of sexual well-being on a personal level, as well as the absence of sexual behavior related diseases, infections or illnesses.<sup>1</sup> Besides that, issues of self-esteem, self-expression, and cultural values are also linked to sexual health. In other words, sexual health involves our behaviors related to reproduction, sexual enjoyment, and also the overall health of our reproductive organs.<sup>1</sup></p>" +
            "<p>In Malaysia, sex education has never really been incorporated into school curriculums as most believe that the topic of sex itself is a taboo subject for school. Although advocates have been pushing for the implementation of sex education in schools over the past few years, it is still an unknown as to when would this plan be finally put into effect. As a result of sex education deficiency, the majority of Malaysian youth possess a relatively low level of sexual health awareness. The Malaysian Youth Sexual and Reproductive Health Survey sponsored by Durex in 2016 had some pretty interesting and rather shocking findings – 1 out of 10 young Malaysians believe that douching a female’s vagina after intercourse prevents pregnancy, 42% believe that withdrawal is an effective method of pregnancy prevention, as many as 35% did not believe that a woman will get pregnant the first time she engages in sex, and a whopping 51% of Malaysian youths did not know that it is possible for a woman to get pregnant during her period.<sup>2</sup> </p>" +
            "<p>In addition to the lack of sex education for Malaysian youths, the conservative nature of the Malaysian culture is also a contributing factor to the severely lacking sexual health knowledge among Malaysians. Most people here do not openly talk about contraception and sexual wellbeing, which adds to the problem of many misconceptions that Malaysians have towards the contraceptives currently available in the market. In general, youths here are mostly taught to be abstinent until marriage, but it is inevitable that a good number of adolescents go through phases of sexual curiosity during puberty and most of them are not equipped with the basic knowledge of safe sex – including the proper use of condoms, morning after pill, oral contraceptives, etc. Furthermore, there is also significant social stigma towards youngsters who purchase birth controls such as condoms because it would imply that they are sexually active at a young age, which is greatly frowned upon by some people. This pressure from the public may very well be one of the contributors to the statistics that show around 14 in every 1,000 underaged girls in Malaysia fall pregnant every year, which adds up to an average of 18,000 girls per year.<sup>3</sup></p>" +
            "<p>Pharmacists, especially those who practice in retail settings, are essentially the first line of help that the people have access to easily. As a healthcare professional, pharmacists should be trained to be comfortable with counseling patients on their sexual health and the appropriate birth control methods whenever such opportunity arises. It is important for pharmacists to realize that whenever the white coat is on, all personal opinions and beliefs should be kept personal, and that every patient consultation should be done professionally.</p>",
          references: [
            {
              text: "Sexual Reproductive Health Awareness – Malaysian AIDS Council. Mac.org.my. <a href='https://www.mac.org.my/v3/what-we-do/programme/srh/' target='_blank'>https://www.mac.org.my/v3/what-we-do/programme/srh/</a>. Published 2019. Accessed November 29, 2019.",
            },
            {
              text: "Durex Youth Survey | Sexual Health | Durex Malaysia. Durex.com.my. <a href='https://www.durex.com.my/youth-survey/' target='_blank'>https://www.durex.com.my/youth-survey/</a>. Published 2019. Accessed December 1, 2019.",
            },
            {
              text: "Kaler S. Malaysia needs sex education - R.AGE. R.AGE. <a href='https://www.rage.com.my/sexeducation/' target='_blank'>https://www.rage.com.my/sexeducation/</a>. Published 2019. Accessed December 1, 2019.",
            },
          ],
        },
        {
          id: 22,
          img_name: "Smoking Cessation a Challenge in Malaysia.jpg",
          img_alt: "Smoking Cessation a Challenge in Malaysia",
          title: "Smoking Cessation a Challenge in Malaysia",
          author: "Dr. Kevin Ong, B. Sc, PharmD",
          date: "30 Sept 2021",
          content:
            "<p>Cigarette smoking is a chronic, relapsing substance use disorder that is sustained by the addiction to nicotine. The chronic use of cigarettes has long been proven to cause respiratory diseases including lung cancers and chronic obstructive pulmonary disease (COPD).<sup>1</sup> Furthermore, cigarette smoking is accounted for an estimation of more than ten percent of all cardiovascular deaths and in addition to that, second-hand smoke predisposes non-smokers to the same diseases that smokers are at higher risk for, and there is an emerging concern with third-hand smoke particularly to infants and young children.<sup>2</sup> Unfortunately, smoking cessation is not a simple task for most smokers and quitting cold turkey will most likely lead to a relapse.</p>" +
            "<p>Globally, there are an estimated 933.1 millions of daily smokers of which 82.3% are males.<sup>3</sup> In Malaysia, it was reported in the National Health and Morbidity Survey 2015 that the national prevalence of current smoker in 2015 was at 22.8% with 20.5% of daily smoker and 2.3% of occasional smoker.<sup>4</sup> Since then, the number of cigarettes consumed in Malaysia increased from a total of 19.3 billions of cigarettes in 2015 to 20.3 billions of cigarettes in 2018, which shows a trend that is contradictory to the global trend of decreasing cigarette consumption.<sup>5</sup> This concerning trend of increasing cigarette consumption is one of the major reasons for the national smoking bans at eateries and public places here in Malaysia. Since the beginning of 2019, the educational enforcement phase of the smoking ban came into effect, and the full implementation of smoking bans will begin on January 1<sup>st</sup>, 2020. Despite the enforcement of the smoking ban, there are still reports of smokers lighting up cigarettes at eateries, and some customers continue to ask for ashtrays.<sup>6</sup> This shows that the implementation of smoking ban is not enough to encourage smokers in Malaysia to quit smoking and other supportive methods are needed to defuse this national epidemic.</p>" +
            "<p>The mQuit services is an integrated smoking cessation service that was inaugurated on 27th November 2015 through a public-private partnership with the objective to expand smoking cessation services’ accessibility throughout public and private facilities.<sup>7</sup> The services provided at a mQuit center include a customized smoking cessation plan, resources and advice on smoking cessation, comprehensive follow-up sessions by dedicated healthcare professionals, and nicotine replacement therapy to facilitate smoking cessation.<sup>7</sup> An individual who is interested in smoking cessation can easily search for the nearest mQuit center on <a href='http://jomquit.moh.gov.my' target='_blank'>http://jomquit.moh.gov.my</a> and begin his or her smoking cessation journey.</p>" +
            "<p>In the pharmacy setting, pharmacists play a crucial role in the promotion of smoking cessation in order to assist in the public health efforts to decrease the number of cigarette smokers in Malaysia. In terms of smoking cessation counseling, pharmacists should first be able to identify smokers and the appropriate interventions based upon the patient’s readiness to quit. The five major steps to a successful intervention are the “5 A’s”: (1) Ask, (2) Advise, (3) Assess, (4) Assist, and (5) Arrange.<sup>8</sup> Firstly, all patients should be asked about their smoking status at every opportunity, including the use of other nicotine or tobacco products besides cigarettes, the frequency and extend of nicotine dependency, and the possibility of second-hand smoke exposure.<sup>9</sup> Secondly, advice to quit smoking should be given to patients in a manner that would encourage the patient’s willingness to quit.<sup>9</sup> Thirdly, patient’s readiness to quit smoking should be assessed. Then, smoking cessation services should be provided to those who are willing to quit smoking. Lastly, follow-up services should be arranged to ensure successful smoking cessation. Smoking cessation can be a difficult journey for those who try to quit smoking, therefore it is important for healthcare providers from different settings to work towards the same goal of helping these patients through their journey to quit smoking.</p>",
          references: [
            {
              text: "What is COPD? | Signs and Symptoms. Copdfoundation.org. <a href='https://www.copdfoundation.org/What-is-COPD/Understanding-COPD/What-is-COPD.aspx' target='_blank'>https://www.copdfoundation.org/What-is-COPD/Understanding-COPD/What-is-COPD.aspx</a>. Published 2019. Accessed December 22, 2019.",
            },
            {
              text: "MPOWER. World Health Organization. <a href='https://www.who.int/tobacco/mpower/en/' target='_blank'>https://www.who.int/tobacco/mpower/en/</a>. Published 2019. Accessed December 22, 2019.",
            },
            {
              text: "Surveillance and monitoring. World Health Organization. <a href='https://www.who.int/tobacco/surveillance/en/' target='_blank'>https://www.who.int/tobacco/surveillance/en/</a>. Published 2019. Accessed December 22, 2019.",
            },
            {
              text: "National Health and Morbidity Survey 2015. Moh.gov.my. <a href='http://www.moh.gov.my/moh/resources/NHMS2015-VolumeV.pdf' target='_blank'>http://www.moh.gov.my/moh/resources/NHMS2015-VolumeV.pdf</a>. Published 2015. Accessed December 22, 2019.",
            },
            {
              text: "Hirschmann R. Malaysia: number of cigarettes consumed 2018 | Statista. Statista. <a href='https://www.statista.com/statistics/668844/malaysia-number-of-cigarettes-consumed/' target='_blank'>https://www.statista.com/statistics/668844/malaysia-number-of-cigarettes-consumed/</a>. Published 2019. Accessed December 22, 2019.",
            },
            {
              text: "Solhi F. Smokers still lighting up in restaurants despite ban. NST Online. <a href='https://www.nst.com.my/news/nation/2019/10/534433/smokers-still-lighting-restaurants-despite-ban' target='_blank'>https://www.nst.com.my/news/nation/2019/10/534433/smokers-still-lighting-restaurants-despite-ban</a>. Published 2019. Accessed December 22, 2019.",
            },
            {
              text: "mQuit Services. <a href='http://jomquit.moh.gov.my/mquitcenters' target='_blank'>http://jomquit.moh.gov.my/mquitcenters</a>. Published 2019. Accessed December 22, 2019.",
            },
            {
              text: "Five Major Steps to Intervention (The &ldquo;5 A&lsquo;s&rdquo;). Ahrq.gov. <a href='https://www.ahrq.gov/prevention/guidelines/tobacco/5steps.html' target='_blank'>https://www.ahrq.gov/prevention/guidelines/tobacco/5steps.html</a>. Published 2019. Accessed December 22, 2019.",
            },
            {
              text: "Park E, Gareen I, Japuntich S et al. Primary Care Provider-Delivered Smoking Cessation Interventions and Smoking Cessation Among Participants in the National Lung Screening Trial. <i>JAMA Intern Med</i>. 2015;175(9):1509. doi:10.1001/jamainternmed.2015.2391",
            },
          ],
        },
        {
          id: 23,
          img_name: "Telepharmacy.jpg",
          img_alt: "Telepharmacy",
          title: "Telepharmacy: Is It Practical for Malaysians?",
          author: "Dr. Sharon Lee, PharmD",
          date: "1 Oct 2021",
          content:
            "<p>The medical field is constantly improving with advances in technology and medical innovations. Compared to decades ago, pharmaceutical technologies have certainly enhanced, and the role of a pharmacist has also evolved over time. Some pharmacists fear that technology may be replacing the profession, while others think that it is complementing the profession. Today, many patients continue to rely on community pharmacies for their prescription medications and other pharmaceutical care. While traditional pharmacies serve their purpose, telepharmacy has quietly been introduced into the Malaysian society through integration with an online pharmacy such as Esyms.<sup>1</sup> Since this is a rather new pharmacy concept for Malaysians, it is useful for pharmacists to understand its function.</p>" +
            "<p>Telepharmacy is basically the delivery of clinical pharmaceutical care to patients via telecommunications without the physical presence of a pharmacist.<sup>2</sup> The services provided generally include patient counselling and drug therapy monitoring, which are some of the basic services available at every traditional pharmacy. This non-traditional pharmacy can appeal differently to different communities. In the urban society, the convenience of telepharmacy may appeal to patients living a busy lifestyle where a trip to the pharmacy may seem like a chore, whereas in the rural community, telepharmacy has the potential to expand pharmacy care access to populations that have limited or no access to a physical pharmacy.<sup>3</sup> Although the idea of telepharmacy expanding the reach of pharmacy services to rural areas seems attractive, the success of telepharmacy services still greatly depends on the level of technological infrastructures in the rural communities.<sup>3</sup> Without a proper way to obtain medications either through reliable delivery service or a local dispensary, the entire concept of telepharmacy would mean nothing to these populations. Furthermore, efficient internet connections and the acquisition of software and devices are major requirements for telepharmacy to work, and this may be a great challenge for rural communities in Malaysia considering the financial burden associated with the technology requirement.</p>" +
            "<p>In addition to the technological requirements that may hinder the application of telepharmacy in Malaysia, its effectiveness is still understudied. The clinical services provided through a telepharmacy removes the interpersonal relationships between a pharmacist and the patient. Despite the digital age, nearly every personal relationship starts with an in-person introduction, which is the foundation of a meaningful interpersonal relationship.<sup>4</sup> A meaningful pharmacist-patient relationship is important in the building of trust, context and the continuity of quality care.<sup>4</sup> A trusted relationship between a pharmacist and a patient will undoubtedly allow for better plan of care through meaning conversations that significantly improve pharmacist-patient communication.</p>" +
            "<p>Telepharmacy has its pros and cons, and it looks like it may be less applicable in most parts of Malaysia given its technology requirement, financial burden associated with that, and the lack of quality care overall. The only advantage that this non-traditional pharmacy may have is the convenience of it that some urban citizens may appreciate. However, a trip to the pharmacy for most people may just be as convenient since most urban and even suburban areas are well saturated with traditional pharmacies. Thus, telepharmacy may seem quite irrelevant in this case. Regardless of the challenges associated with the overall operability of telepharmacy in Malaysia, it is still an area of pharmaceutical care that can be further explored and innovated to be better applied in our country.</p>",
          references: [
            {
              text: "Esyms. <a href='https://shop.esyms.com/#!/telepharmacy' target='_blank'>https://shop.esyms.com/#!/telepharmacy</a>.",
            },
            {
              text: "Poudel A, Nissen L. Telepharmacy: a pharmacist&rsquo;s perspective on the clinical benefits and challenges. <i>Integrated Pharmacy Research and Practice</i>. 2016;Volume 5:75-82. doi:10.2147/iprp.s101685",
            },
            {
              text: "Baldoni S, Amenta F, Ricci G. Telepharmacy Services: Present Status and Future Perspectives: A Review. <i>Medicina (B Aires)</i>. 2019;55(7):327. doi:10.3390/medicina55070327",
            },
            {
              text: "Trygstad T. Here Is What Telepharmacy Advocates Get Wrong. Pharmacy Times. <a href='https://www.pharmacytimes.com/publications/issue/2019/August2019/here-is-what-telepharmacy-advocates-get-wrong' target='_blank'>https://www.pharmacytimes.com/publications/issue/2019/August2019/here-is-what-telepharmacy-advocates-get-wrong</a>. Published 2019. Accessed January 11, 2020.",
            },
          ],
        },
        {
          id: 24,
          img_name:
            "E-cigarettes and Vapes are Not Aids for Smoking Cessation.jpg",
          img_alt: "E-cigarettes and Vapes are Not Aids for Smoking Cessation",
          title: "E-cigarettes and Vapes are Not Aids for Smoking Cessation",
          author: "Dr. Kevin Ong, B. Sc., PharmD",
          date: "3 Oct 2021",
          content:
            "<p>E-cigarettes and vaping devices are battery-operated devices that heat up a liquid to form vapors or aerosol that the user then inhales. These devices heat up a variety of flavorings that may contain nicotine or other potentially harmful substances.<sup>1</sup> Vaping-associated lung injury (VALI) or vaping-associated pulmonary injury (VAPI) is a lung disease that recently came into the spotlight around September 2019 after reports of severe lung injury that can be life-threatening were linked to the long-term use of vaping products in the United States. </p>" +
            "<p>Since reports of lung injury from vaping are quite recent, it is still unclear to scientists as to how often might vaping lead to lung issues or who are at higher risks of developing VALI.<sup>1</sup> While these recent cases of severe lung disease are alarming and are cause for concern, vaping is also associated with a number of other negative health effects that are also worrisome, including the addictive effects of vaping nicotine, carcinogenic substances that have been found in e-cigarettes, and the risk of explosions and burns with these devices due to defective batteries just to name a few.</p>" +
            "<p>Some may argue that vaping or the use of e-cigarettes with nicotine helps some people to quit smoking, and there are several evidence suggesting such claim.<sup>1</sup> However, its efficacy is still unclear in comparison to a nicotine patch or other currently available methods of smoking cessation.<sup>1</sup> In addition to that, many smokers who vape continue to use both tobacco cigarettes and e-cigarettes, and it has been reported that teens and young adults who vape are more likely to begin smoking tobacco cigarettes.<sup>1</sup></p>" +
            "<p>In Malaysia, there are currently no e-cigarettes or vape products that are registered as smoking cessation aids or medications according to Deputy Health Minister Dr. Lee Boon Chye on November 13, 2019.<sup>2</sup> However, vape liquid containing nicotine is regulated by the Ministry of Health under the Poisons Act 1952, and the sale of such product can only be carried out by licensed pharmacists and registered medical practitioners for medical purposes.<sup>2</sup> However, pharmacists and medical practitioners have been reluctant to sell vape products containing nicotine for reasons mentioned above and many feel that selling these products would go against their roles as healthcare providers.<sup>3</sup>  </p>" +
            "<p>Despite the regulation on nicotine-containing vape liquids by the Ministry of Health, many vape product vendors continue to sell nicotine-containing vape liquids illegally.<sup>4</sup> The rising popularity of vaping has raised public health concerns as reports of vaping leading to people who have never smoked, such as women and school children, to become addicted to nicotine and vaping are on the rise, which prompted associations and NGOs throughout the country to sign a memorandum, urging the government to impose a total ban on the use of e-cigarettes and vaping immediately.<sup>4</sup> As healthcare providers, perhaps it is time to us to think about grouping the use of vape or e-cigarettes together with tobacco cigarette smoking, and to think about initiatives to encourage patients who vape to quit using such products just like our current public health efforts with smoking cessation.</p>",
          references: [
            {
              text: "Robert H. Shmerling M. Can vaping damage your lungs? What we do (and don’t) know - Harvard Health Blog. Harvard Health Blog. <a href='https://www.health.harvard.edu/blog/can-vaping-damage-your-lungs-what-we-do-and-dont-know-2019090417734' target='_blank'>https://www.health.harvard.edu/blog/can-vaping-damage-your-lungs-what-we-do-and-dont-know-2019090417734</a>. Published 2019. Accessed January 20, 2020.",
            },
            {
              text: "E-cigarettes, vape not registered as aids to quit smoking, says Lee. Free Malaysia Today. <a href='https://www.freemalaysiatoday.com/category/nation/2019/11/13/e-cigarettes-vape-not-registered-as-aids-to-quit-smoking-says-lee/' target='_blank'>https://www.freemalaysiatoday.com/category/nation/2019/11/13/e-cigarettes-vape-not-registered-as-aids-to-quit-smoking-says-lee/</a>. Published 2019. Accessed January 20, 2020.",
            },
            {
              text: "Jamaluddin N. Pharmacists not keen on selling vape liquid | Malay Mail. Malaymail.com. <a href='https://www.malaymail.com/news/malaysia/2015/11/12/pharmacists-not-keen-on-selling-vape-liquid/1003301' target='_blank'>https://www.malaymail.com/news/malaysia/2015/11/12/pharmacists-not-keen-on-selling-vape-liquid/1003301</a>. Published 2015. Accessed January 20, 2020.",
            },
            {
              text: "Loh F. Fifty NGOs call for total ban on e-cigarettes and vaping. The Star Online. <a href='https://www.thestar.com.my/news/nation/2019/10/24/fifty-ngos-call-for-total-ban-on-e-cigarettes-and-vaping' target='_blank'>https://www.thestar.com.my/news/nation/2019/10/24/fifty-ngos-call-for-total-ban-on-e-cigarettes-and-vaping</a>. Published 2019. Accessed January 20, 2020.",
            },
          ],
        },
        {
          id: 25,
          img_name: "Women’s Health.jpg",
          img_alt: "Women’s Health",
          title: "Women’s Health",
          author: "Dr. Sharon Lee, PharmD",
          date: "4 Oct 2021",
          content:
            "<p>Women’s health is an area of medicine that has gained attention over the last few years. Unique health issues that are only present in women include pregnancy, menopause, and conditions related to the female organs. In addition, some of the health conditions or diseases that affect both men and women can affect women differently and in some cases the consequences or complications from those conditions can be more severe in women. </p>" +
            "<p>According to a study published in the Journal of the American Heart Association, women are found to be up to three times more likely to die following a serious heart attack than men as a result of unequal care and treatment.<sup>1</sup> Approximately half of the women in the study were initially misdiagnosed, and that was largely attributed to the disease presentation difference between men and women, which led to the delay in proper diagnostic tests for many of these women.<sup>2</sup> Myocardial infarctions (MI) are often seen as a male health issue as the signs and symptoms of a MI such as chest pain is more prominent in the male population, whereas the women may simply present with numbness in the left arm during an active heart attack and many women tend to just brush the numbness off lightly.<sup>2</sup> </p>" +
            "<p>Other health problems that affect women more than men include the effects of sexually transmitted diseases that can be more serious in women, rates of osteoarthritis are higher in women than in men, and women are more likely to contract urinary tract infections simply due to the female anatomy.<sup>3</sup> The scope of women’s health is broad and is impossible to fully cover this subject matter in this article, but it is undeniable that women’s health is an area of healthcare that requires more consideration from healthcare professionals.</p>" +
            "<p>In the pharmacy, some common conditions that female patients might present with include the need for emergency contraceptives or oral contraceptives, menstrual cramps, urinary tract infection (UTI), and yeast infection. Some of these conditions are exclusive to the female population, and thus patient education or consultation would have to be tailored to the female patients specifically. For example, while UTI is a condition that can affect both men and women, patient counseling for the female patients would be slightly different in which the healthcare provider should advice the female patient to wipe from the front to the back of her bum in one direction only after using the toilet as an effort to prevent UTI solely due to the proximity between the female anatomy and the anus. </p>" +
            "<p>Patient counselling for female specific conditions can sometimes be uncomfortable for our male colleagues in Malaysia perhaps due to the conservative nature of our culture. However, as a healthcare professional, it is our duty to uphold the professionalism of the pharmacist profession. Therefore, during patient consultation, personal opinions and beliefs should be put aside and the focus should be placed on the patient’s best interest.</p>",
          references: [
            {
              text: "New study: Women more likely to die after a heart attack due to unequal treatment - World Heart Federation. World Heart Federation. <a href='https://www.world-heart-federation.org/news/new-study-women-likely-die-heart-attack-due-unequal-treatment/' target='_blank'>https://www.world-heart-federation.org/news/new-study-women-likely-die-heart-attack-due-unequal-treatment/</a>. Published 2018. Accessed February 3, 2020.",
            },
            {
              text: "Alabas O, Gale C, Hall M et al. Sex Differences in Treatments, Relative Survival, and Excess Mortality Following Acute Myocardial Infarction: National Cohort Study Using the SWEDEHEART Registry. <i>J Am Heart Assoc</i>. 2017;6(12). doi:10.1161/jaha.117.007123",
            },
            {
              text: "Women's Health: MedlinePlus. Medlineplus.gov. <a href='https://medlineplus.gov/womenshealth.html' target='_blank'>https://medlineplus.gov/womenshealth.html</a>. Accessed February 3, 2020.",
            },
            {
              text: "Pharmacists expand focus on women's health. Drug Topics. <a href='https://www.drugtopics.com/rfc/pharmacists-expand-focus-womens-health' target='_blank'>https://www.drugtopics.com/rfc/pharmacists-expand-focus-womens-health</a>. Published 2010. Accessed February 3, 2020.",
            },
          ],
        },

        /*End Sheau Hui*/
      ],
    };
  },
  mounted() {
    this.fetchBlog();
  },
  methods: {
    fetchBlog() {
      this.blogId = parseInt(this.blogId);
      this.blog = this.blogs.find((x) => x.id === this.blogId);
    },
  },
};
</script>

<style scoped>
.v-image {
  width: 100%;
  height: 60vh;
}

h1,
h2 {
  font-family: "BigCaslon-Rom";
  letter-spacing: 1px;
  color: #2b2b2b;
}

.section-text >>> a {
  color: #2b2b2b !important;
}
.section-text >>> a:hover {
  text-decoration: underline;
}

/*Sheau Hui*/

.section-text >>> table,
.section-text >>> th,
.section-text >>> td {
  border: 1px solid #ced5cc;
  border-collapse: collapse;
}

/*Change */
.section-text >>> table {
  width: 100%;
  display: block;
  overflow-x: auto;
}

.section-text >>> th {
  text-align: center;
  background-color: #ced5cc;
}

.section-text >>> th,
.section-text >>> td {
  padding: 15px;
}

/*Change */
/* .section-text >>> ul {
  list-style-type: square;
} */

.section-text >>> li {
  margin-left: 20px;
}

/*End Sheau Hui*/

.reference-text >>> a {
  color: #2b2b2b !important;
}
.reference-text >>> a:hover {
  text-decoration: underline;
}

.post-text,
.reference-text {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #2b2b2b;
}

@media (min-width: 1904px) {
  .v-image {
    height: 40vh;
  }
}

@media (min-width: 1264px) {
  .blog-details > .container {
    width: 70%;
  }

  h1 {
    font-size: 34px;
  }
}

@media (max-width: 1263px) {
  .post-text,
  .reference-text {
    font-size: 14px;
  }
}

@media (max-width: 959px) {
  .v-image {
    height: 50vh;
  }
}

@media (max-width: 599px) {
  .v-image {
    height: 40vh;
  }
}
</style>